import UserAddEdit from 'components/admin/users/UserAddEdit';
import UserList from 'components/client/users/UserList';
import { ProtectedRoute } from 'components/elements';
import { Component } from 'react';
import { Switch } from 'react-router-dom';

class Users extends Component {
    render() {
        const { clientResponse, match, isClientBio } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={UserList}
                        clientResponse={clientResponse}
                        isClientBio={isClientBio}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add`}
                        component={UserAddEdit}
                        clientResponse={clientResponse}
                        mode="client"
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/:id`}
                        component={UserAddEdit}
                        clientResponse={clientResponse}
                        mode="client"
                        exact
                    />
                </Switch>
            </div>
        );
    }
}

export default Users;
