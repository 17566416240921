const routes = {
    integrations: {
        href: '/integrations',
        text: 'Integrations',
        skipNavigation: true,
    },
    integrations_elogbooks: {
        href: '/integrations/elogbooks',
        text: 'Vantify',
        skipNavigation: false,
    },
    integrations_elogbooks_add: {
        href: '/integrations/elogbooks/add',
        text: 'Add Integration',
        skipNavigation: true,
    },
    profile: {
        href: '/profile',
        text: 'My Profile',
        skipNavigation: true,
    },
    profile_details: {
        href: '/profile/details',
        text: 'Details',
    },
    profile_details_edit: {
        href: '/profile/details/edit',
        text: 'Edit',
    },
};

export default routes;
