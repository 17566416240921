import {
    Button,
    Checkbox,
    FormGroup,
    H1,
    H3,
    InputGroup,
} from '@blueprintjs/core';
import { ChangeEvent, Dispatch, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearAssessmentDiscount,
    setAddress,
    setAssessmentDiscount,
    updatingBreadcrumbResolves,
} from 'action';
import { ButtonInput, ButtonLink, HTMLSelect } from 'components/elements';
import { COUNTRY_CODE_OPTIONS } from 'constants/countries';
import { USER_TITLE_OPTIONS } from 'constants/user';
import { toMoney } from 'helpers/helpers';
import {
    AssessmentTypeData,
    ButtonInputMessage,
    DiscountData,
    StickerDetails,
} from 'interface';
import {
    AssessmentTypeService,
    DiscountService,
    HTTP,
    Response,
    StateHandler,
} from 'service';

const AssessmentTypePurchase = (props: any) => {
    const dispatch = useDispatch();

    const { match, history } = props;
    const rootResponse = useSelector((state: any) => state.root.response);

    const [assessment, setAssessment]: [
        Partial<AssessmentTypeData>,
        Dispatch<any>
    ] = useState({
        name: undefined,
        description: undefined,
        _links: {},
    });
    const [requiresStickers, setRequiresStickers]: [boolean, Dispatch<any>] =
        useState(true);
    const [stickerDetails, setStickerDetails]: [StickerDetails, Dispatch<any>] =
        useState({
            title: '',
            firstName: '',
            surname: '',
            registeredCompanyName: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            town: '',
            county: '',
            postCode: '',
            country: '',
        } as StickerDetails);
    const [voucher, setVoucher]: [DiscountData | void, Dispatch<any>] =
        useState();
    const [voucherMessage, setVoucherMessage]: [
        ButtonInputMessage | void,
        Dispatch<any>
    ] = useState();

    useEffect(() => {
        dispatch(
            updatingBreadcrumbResolves({
                company: props.companyResponse,
                assessment: undefined,
            })
        );
        dispatch(clearAssessmentDiscount());

        (async () => {
            const assessment = await AssessmentTypeService.loadAssessment(
                match.params.id
            );
            if (assessment) {
                dispatch(
                    updatingBreadcrumbResolves({
                        assessment: { purchase: 'Purchase ' + assessment.name },
                    })
                );
                setAssessment(assessment);
            }
        })();

        HTTP.get(Response.getLink(rootResponse, 'company')).then((response) => {
            if (response) {
                const companyResponse = response.data;
                setStickerDetails({
                    title: rootResponse.user.title,
                    firstName: rootResponse.user.forename,
                    surname: rootResponse.user.surname,
                    registeredCompanyName:
                        companyResponse.registeredCompanyName,
                    addressLine1: companyResponse.addressLine1,
                    addressLine2: companyResponse.addressLine2,
                    addressLine3: companyResponse.addressLine3,
                    town: companyResponse.town,
                    county: companyResponse.county,
                    postCode: companyResponse.postCode,
                    country: companyResponse.country,
                } as StickerDetails);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assessmentImage = () => {
        return assessment._links.file ? (
            <img
                className="badge-img"
                alt={assessment._links.file.title}
                title={assessment._links.file.title}
                src={
                    process.env.REACT_APP_BASE_URL +
                    assessment._links.file.publicUrl
                }
            />
        ) : (
            <></>
        );
    };

    const handleBuyNowBtn = (e: FormEvent) => {
        e.preventDefault();

        if (requiresStickers) {
            dispatch(setAddress(stickerDetails));
        }
        dispatch(setAssessmentDiscount(voucher));

        history.push(`/assessments/${assessment.id}/payment`);
    };

    const checkVoucher = async (value: string) => {
        const voucher = await DiscountService.getDiscountForAssessment(
            value,
            Response.getLink(assessment, 'self')
        );

        if (!voucher) {
            setVoucherMessage({
                type: 'error',
                message: 'The voucher code you entered is not valid',
            });

            return;
        }

        setVoucher(voucher);
        setVoucherMessage({
            type: 'success',
            message: 'You successfully redeemed a voucher code',
        });
    };

    const voucherDiscount = () => {
        if (!voucher) {
            return 0;
        }
        const discountValue =
            (parseFloat(assessment.pricing.standardAssessmentFeeExcludingTax) *
                voucher.discount) /
            100;

        return isNaN(discountValue) ? 0 : discountValue;
    };

    const withVoucher: (price: number) => number = (price: number) => {
        if (!voucher) {
            return price;
        }
        price *= (100 - voucher.discount) / 100;

        return isNaN(price) ? 0 : price;
    };

    return (
        <div className="page">
            <div className="page-wrapper no-styling AssessmentPurchase">
                <H1 className="clearfix">Purchase {assessment.name}</H1>
                <form
                    className="clearfix"
                    onSubmit={(event: FormEvent) => handleBuyNowBtn(event)}
                >
                    <div className="flex">
                        <div className="flex-1">{assessmentImage()}</div>
                        <div className="flex-5">
                            <H3>Description</H3>
                            <p>{assessment.description}</p>

                            {!assessment.pricing ? null : (
                                <>
                                    <H3>Pricing</H3>

                                    <p>
                                        The price for this assessment is
                                        based on the number of 'effective
                                        employees' (made up of employees and
                                        labour only contractors) you have.
                                    </p>

                                    <p>
                                        Your company profile indicates that
                                        you currently have
                                        <b>
                                            {' '}
                                            {
                                                assessment.userOrganisationSize
                                            }{' '}
                                        </b>
                                        effective employees. If this is
                                        incorrect, please go to your company
                                        profile page and update.
                                    </p>

                                    <section className="voucher">
                                        <ButtonInput
                                            title="Add Voucher Code"
                                            placeholder="Voucher code"
                                            disabled={!!voucher}
                                            onUpdate={checkVoucher}
                                            message={voucherMessage}
                                        />
                                    </section>

                                    <dl className="grid small">
                                        <dt>Assessment</dt>
                                        <dd>
                                            {toMoney(
                                                assessment.pricing
                                                    .standardAssessmentFeeExcludingTax
                                            )}
                                        </dd>
                                        {voucher && (
                                            <>
                                                <dt>Voucher Applied</dt>
                                                <dd>
                                                    -
                                                    {toMoney(
                                                        voucherDiscount()
                                                    )}
                                                </dd>
                                                <dt className="bold mb-1">
                                                    Sub-Total
                                                </dt>
                                                <dd className="bold mb-1">
                                                    {toMoney(
                                                        withVoucher(
                                                            assessment
                                                                .pricing
                                                                .standardAssessmentFeeExcludingTax
                                                        )
                                                    )}
                                                </dd>
                                            </>
                                        )}

                                        <dt>VAT</dt>
                                        <dd>
                                            {assessment.pricing
                                                .standardAssessmentFeeTax
                                                ? toMoney(
                                                        withVoucher(
                                                            assessment.pricing
                                                                .standardAssessmentFeeTax
                                                        )
                                                    )
                                                : 'N/A'}
                                        </dd>

                                        <dt className="bold">Total</dt>
                                        <dd className="bold">
                                            {toMoney(
                                                withVoucher(
                                                    assessment.pricing
                                                        .standardAssessmentFeeIncludingTax
                                                )
                                            )}
                                        </dd>
                                    </dl>
                                    {assessment.isSSIPAccreditation ===
                                        true && (
                                        <div className="mt-5">
                                            <Checkbox
                                                checked={requiresStickers}
                                                onChange={() =>
                                                    setRequiresStickers(
                                                        (
                                                            prevRequiresStickers: boolean
                                                        ) =>
                                                            !prevRequiresStickers
                                                    )
                                                }
                                            >
                                                If you would like a
                                                Vantify Supply Chain sticker pack to
                                                be sent to you, please tick
                                                this box.
                                            </Checkbox>
                                        </div>
                                    )}
                                    {
                                        requiresStickers &&
                                        assessment.isSSIPAccreditation === true && (
                                        <div className="mt-5">
                                            <FormGroup
                                                label="Title"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="title"
                                            >
                                                <HTMLSelect
                                                    id="title"
                                                    fill={true}
                                                    defaultValue={
                                                        stickerDetails.title
                                                    }
                                                    onChange={(e: Event) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    options={
                                                        USER_TITLE_OPTIONS
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="First Name"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="firstName"
                                            >
                                                <InputGroup
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    minLength={1}
                                                    maxLength={60}
                                                    value={
                                                        stickerDetails.firstName
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Surname"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="surname"
                                            >
                                                <InputGroup
                                                    id="surname"
                                                    placeholder="Surname"
                                                    minLength={1}
                                                    maxLength={60}
                                                    value={
                                                        stickerDetails.surname
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Company Name"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="registeredCompanyName"
                                            >
                                                <InputGroup
                                                    id="registeredCompanyName"
                                                    placeholder="Company Name"
                                                    minLength={1}
                                                    maxLength={60}
                                                    value={
                                                        stickerDetails.registeredCompanyName
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Address Line 1"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="addressLine1"
                                            >
                                                <InputGroup
                                                    id="addressLine1"
                                                    fill={true}
                                                    placeholder="Address Line 1"
                                                    maxLength={100}
                                                    value={
                                                        stickerDetails.addressLine1
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Address Line 2"
                                                inline={true}
                                                className="form-fill"
                                                labelFor="addressLine2"
                                            >
                                                <InputGroup
                                                    id="addressLine2"
                                                    fill={true}
                                                    maxLength={100}
                                                    placeholder="Address Line 2"
                                                    value={
                                                        stickerDetails.addressLine2
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Address Line 3"
                                                inline={true}
                                                className="form-fill"
                                                labelFor="addressLine3"
                                            >
                                                <InputGroup
                                                    id="addressLine3"
                                                    fill={true}
                                                    placeholder="Address Line 3"
                                                    maxLength={100}
                                                    value={
                                                        stickerDetails.addressLine3
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Town/City"
                                                inline={true}
                                                className="form-fill required"
                                                labelFor="town"
                                            >
                                                <InputGroup
                                                    id="town"
                                                    fill={true}
                                                    placeholder="Town/City"
                                                    maxLength={100}
                                                    value={
                                                        stickerDetails.town
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="County/Region"
                                                inline={true}
                                                className="form-fill"
                                                labelFor="county"
                                            >
                                                <InputGroup
                                                    id="county"
                                                    fill={true}
                                                    maxLength={100}
                                                    placeholder="County/Region"
                                                    value={
                                                        stickerDetails.county
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Post Code"
                                                inline={true}
                                                className="form-fill"
                                                labelFor="postCode"
                                            >
                                                <InputGroup
                                                    id="postCode"
                                                    fill={true}
                                                    maxLength={10}
                                                    placeholder="Post Code"
                                                    value={
                                                        stickerDetails.postCode
                                                    }
                                                    onChange={(e) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>

                                            <FormGroup
                                                inline={true}
                                                label="Country"
                                                labelFor="country"
                                                className="form-fill required"
                                            >
                                                <HTMLSelect
                                                    id="country"
                                                    required
                                                    fill={true}
                                                    options={
                                                        COUNTRY_CODE_OPTIONS
                                                    }
                                                    value={stickerDetails.country}
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>
                                                    ) =>
                                                        setStickerDetails(
                                                            StateHandler.getStateObject(
                                                                e,
                                                                stickerDetails
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                    <p className="mt-5">
                                        Any purchases made on Vantify Supply Chain
                                        are in accordance with our Service
                                        Provider's{' '}
                                        <a
                                            href="https://prosure360.co.uk/terms_and_conditions"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span>
                                                Terms &amp; conditions
                                            </span>
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://prosure360.co.uk/terms_of_use"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span>Terms of Use</span>
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://www.vantify.com/privacy-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span>Privacy Policy</span>
                                        </a>
                                    </p>
                                </>
                            )}

                            <div className="buttons flex-between mt-5">
                                <ButtonLink to="/shop">Cancel</ButtonLink>
                                <Button type="submit" intent="primary">
                                    Buy Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AssessmentTypePurchase;
