import { H1 } from '@blueprintjs/core';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ItemCount, Paginator, Reload, Table } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { CERTIFICATION_MEMBERSHIP_STATUSES } from 'constants/certificationMemberships';
import { Filters, HTTP, Location, Response } from 'service';

const CertificationMembershipsList = (props) => {
    const defaultFilters = {
        order: '-expiry',
    };

    const sectionRoot = props.location.pathname.includes('search')
        ? `/search/${props.companyResponse.id}`
        : `/company`;

    const [listState, setListState] = useState({
        collectionResponse: {},
        filter: queryString.parse(props.location.search),
        filterData: Filters.getFilterStateFromUrl(
            props.location.search.slice(1),
            props.filterData
        ),
        isLoading: false,
    });

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRoute = (row) => {
        return `${sectionRoot}/certification-memberships/${row.id}`;
    };

    const getRouteCertificate = (row) => {
        return row._links['certificate-file'].download;
    };

    const getColourClass = (row) => {
        switch(row.status) {
            case 'ACTIVE':
                return 'success';
            case 'EXPIRED':
            default:
                return 'error';
        }
    }

    const collection = listState.collectionResponse || {};

    const columns = [
        {
            name: 'Certification/Membership',
            property: 'name',
            sortable: true,
            grow: 4,
        },
        {
            name: 'Certificate/Registration Number',
            property: 'certificateRegistrationNumber',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Status',
            property: 'status',
            colourClass: getColourClass,
            type: 'mapping',
            mapping: CERTIFICATION_MEMBERSHIP_STATUSES,
            grow: 2,
        },
        {
            name: 'Commencement Date',
            property: 'commencementDate',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Expiry Date',
            property: 'expiresAt',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        { name: 'Comments', property: 'comments', type: 'text', grow: 2 },
        {
            name: '',
            property: '',
            type: 'external-link',
            grow: 2,
            route: getRouteCertificate,
            buttonIntent: 'primary',
            buttonText: 'View Certificate',
            right: true,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const load = async (params = {}, paramsOverride = false) => {
        if (listState.isLoading) {
            return;
        }

        const { companyResponse, history, location } = props;
        const { filter } = listState;

        const filterParams = paramsOverride
            ? params
            : Object.assign({}, defaultFilters, filter, params);
        Location.setQueryStringFromObject(history, location, filterParams);

        setListState({
            ...listState,
            filter: filterParams,
            isLoading: true,
        });

        try {
            let response = await HTTP.get(
                Response.getLink(companyResponse, 'certification-memberships'),
                Filters.convertFilterDataToRequestData(filterParams)
            );

            if (response) {
                setListState({
                    ...listState,
                    collectionResponse: response.data,
                    isLoading: false,
                });

                return true;
            }
        } catch (e) {
            toast.error('Unable to fetch a list of Certificate memberships.');
            console.warn(e.code, e.message);

            return false;
        }
    };

    return (
        <div className="CertificationMembershipsList">
            <H1>
                Certification &amp; Memberships
                <ItemCount count={collection.count} />
                <Reload load={load} />
            </H1>

            <Loading isLoading={listState.isLoading}>
                <NoResultsFound
                    label="No Current Certification Memberships"
                    count={collection.count}
                >
                    <Table
                        data={collection.certificationMembershipInstances}
                        columns={columns}
                        onSort={load}
                        ordering={listState.filter.order}
                    />

                    <Paginator
                        page={collection.page}
                        count={collection.count}
                        limit={collection.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default CertificationMembershipsList;
