import { Component } from 'react';
import { Switch } from 'react-router-dom';

import UserAddEdit from 'components/admin/users/UserAddEdit';
import UserAddExisting from 'components/company/users/UserAddExisting';
import UserList from 'components/company/users/UserList';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class Users extends Component {
    render() {
        const { reloadData, companyResponse, match } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={UserList}
                        companyResponse={companyResponse}
                        reloadData={reloadData}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add`}
                        component={UserAddEdit}
                        companyResponse={companyResponse}
                        reloadData={reloadData}
                        mode="company"
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add-existing`}
                        component={UserAddExisting}
                        companyResponse={companyResponse}
                        reloadData={reloadData}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/:id`}
                        component={UserAddEdit}
                        companyResponse={companyResponse}
                        mode="company"
                        exact
                    />
                </Switch>
            </div>
        );
    }
}

export default Users;
