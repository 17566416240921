import { Button, H1 } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { ButtonLink, Icon } from 'components/elements';
import {
    ASSESSMENT_TYPE_TYPE_CLIENT,
    ASSESSMENT_TYPE_TYPE_PROSURE,
} from 'constants/assessmentTypeTypes';
import { serviceProviderSizeApplicabilityMapping } from 'interface';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';

class AssessmentTypeSummary extends Component {
    static propTypes = {
        assessmentTypeResponse: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    getClientAssessmentProcess = () => {
        let assessorText = 'none';

        if (
            this.props.assessmentTypeResponse
                .isProsureAssessorReviewerRequired === 'true' ||
            this.props.assessmentTypeResponse
                .isProsureAssessorReviewerRequired === true
        ) {
            assessorText = 'Internal Vantify Assessor';
        } else if (
            this.props.assessmentTypeResponse.isClientAssessorRequired ===
                'true' ||
            this.props.assessmentTypeResponse.isClientAssessorRequired === true
        ) {
            assessorText = 'Client Assessed';
        } else if (
            this.props.assessmentTypeResponse.allowAutomatedPassFail ===
                'true' ||
            this.props.assessmentTypeResponse.allowAutomatedPassFail === true
        ) {
            assessorText = 'Automatic';
        }

        return assessorText;
    };

    createNewVersion = () => {
        const { assessmentTypeResponse, history, mergeResponse } = this.props;
        const link = Response.getLink(
            assessmentTypeResponse,
            'create-new-version'
        );

        if (link) {
            HTTP.post(link)
                .then((response) => {
                    const assessmentTypeResponse = response.data;
                    if (Response.getLink(assessmentTypeResponse, 'self')) {
                        history.push(
                            '/admin' +
                                Response.getLink(assessmentTypeResponse, 'self')
                        );
                        mergeResponse(assessmentTypeResponse);
                    }
                })
                .catch((response) => {
                    toast.error('Unable to create new version');
                });
        }
    };

    makeActive = () => {
        const { assessmentTypeResponse, mergeResponse } = this.props;
        const link = Response.getLink(assessmentTypeResponse, 'make-active');

        if (link) {
            HTTP.put(link)
                .then((response) => {
                    const assessmentTypeResponse = response.data;
                    if (Response.getLink(assessmentTypeResponse, 'self')) {
                        mergeResponse(assessmentTypeResponse);
                    }
                })
                .catch((response) => {
                    toast.error('Unable to make assessment type active');
                });
        }
    };

    deactivateVersion = () => {
        const { assessmentTypeResponse, mergeResponse } = this.props;
        const link = Response.getLink(
            assessmentTypeResponse,
            'deactivate-version'
        );

        if (link) {
            HTTP.put(link)
                .then((response) => {
                    const assessmentTypeResponse = response.data;
                    if (Response.getLink(assessmentTypeResponse, 'self')) {
                        mergeResponse(assessmentTypeResponse);
                    }
                })
                .catch((response) => {
                    toast.error('Unable to deactivate assessment');
                });
        }
    };

    render() {
        const { assessmentTypeResponse, match } = this.props;
        const fileInfo = assessmentTypeResponse._links.file ? (
            <>
                <dt>Badge Image</dt>
                <dd>
                    <img
                        className="badge-img"
                        alt={assessmentTypeResponse._links.file.title}
                        title={assessmentTypeResponse._links.file.title}
                        src={
                            process.env.REACT_APP_BASE_URL +
                            assessmentTypeResponse._links.file.publicUrl
                        }
                    ></img>
                </dd>
            </>
        ) : null;

        return (
            <div className="AssessmentTypeSummary">
                <H1 className="clearfix">
                    Summary
                    {Response.getLink(assessmentTypeResponse, 'edit') ===
                    null ? null : (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/edit`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="pencil" />
                            Edit
                        </ButtonLink>
                    )}
                    {Response.getLink(
                        assessmentTypeResponse,
                        'create-new-version'
                    ) && (
                        <Button
                            type="button"
                            intent="primary"
                            className="float-right"
                            onClick={this.createNewVersion}
                        >
                            Create New Version
                        </Button>
                    )}
                    {Response.getLink(
                        assessmentTypeResponse,
                        'make-active'
                    ) && (
                        <Button
                            type="button"
                            intent="primary"
                            className="float-right"
                            onClick={this.makeActive}
                        >
                            Make Active
                        </Button>
                    )}
                    {Response.getLink(
                        assessmentTypeResponse,
                        'deactivate-version'
                    ) && (
                        <Button
                            type="button"
                            intent="primary"
                            className="float-right"
                            onClick={this.deactivateVersion}
                        >
                            Deactivate Version
                        </Button>
                    )}
                </H1>

                <dl className="horizontal">
                    <dt>Short Name</dt>
                    <dd>{assessmentTypeResponse.shortName}</dd>
                    <dt>Description</dt>
                    <dd>{assessmentTypeResponse.description}</dd>
                    <dt>Vantify or Client Assessment</dt>
                    <dd>
                        {assessmentTypeResponse.type ===
                        ASSESSMENT_TYPE_TYPE_PROSURE.value
                            ? ASSESSMENT_TYPE_TYPE_PROSURE.label
                            : ASSESSMENT_TYPE_TYPE_CLIENT.label}
                    </dd>
                    <dt>Service Provider Size Applicability</dt>
                    <dd>
                        {
                            serviceProviderSizeApplicabilityMapping[
                                assessmentTypeResponse
                                    .serviceProviderSizeApplicability
                            ]
                        }
                    </dd>
                    {assessmentTypeResponse._links.client ? (
                        <>
                            <dt>Client</dt>
                            <dd>{assessmentTypeResponse._links.client.name}</dd>
                        </>
                    ) : null}
                    {assessmentTypeResponse.type === 'prosure' && (
                        <>
                            <dt>Vantify SSIP Verified Assessment</dt>
                            <dd>
                                {assessmentTypeResponse.isSSIPAccreditation
                                    ? 'Yes'
                                    : 'No'}
                            </dd>
                            <dt>Vantify Pre-Qualified Assessment</dt>
                            <dd>
                                {assessmentTypeResponse.isProsurePreQualified
                                    ? 'Yes'
                                    : 'No'}
                            </dd>
                            <dt>Vantify DTS Assessment</dt>
                            <dd>
                                {assessmentTypeResponse.isSSIPDTS
                                    ? 'Yes'
                                    : 'No'}
                            </dd>
                        </>
                    )}
                    <dt>Assessment Process</dt>
                    <dd>{this.getClientAssessmentProcess()}</dd>
                    <dt>Assessment Review Frequency</dt>
                    <dd className="capitalise">
                        {assessmentTypeResponse.reviewFrequency}
                    </dd>
                    <dt>SLA Applicable</dt>
                    <dd>
                        {assessmentTypeResponse.isSLAApplicable ? 'Yes' : 'No'}
                    </dd>

                    {assessmentTypeResponse.type === 'prosure' && (
                        <>
                            <dt>Answers & Evidence Visible to Clients</dt>
                            <dd>
                                {assessmentTypeResponse.isVisibleToClient
                                    ? 'Yes'
                                    : 'No'}
                            </dd>
                        </>
                    )}
                    {assessmentTypeResponse.isSSIPAccreditation ? (
                        <>
                            <dt>SSIP Scope Category</dt>
                            <dd className="capitalise">
                                {assessmentTypeResponse.SSIPScopeCategories.join(
                                    ', '
                                )}
                            </dd>
                        </>
                    ) : null}
                    {fileInfo}
                </dl>
            </div>
        );
    }
}

export default AssessmentTypeSummary;
