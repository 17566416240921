import { Button } from '@blueprintjs/core';
import { ButtonLink, Icon, List } from 'components/elements';
import { ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import { CLIENT_ROLE_OPTIONS } from 'constants/role';
import { isAdmin, isClientAdmin } from 'helpers/helpers';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';
import { ClientService, HTTP, Response } from 'service';

class UserList extends Component {
    defaultFilters = {
        order: 'forename',
    };

    static defaultProps = {
        filterData: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            addDisabled: false,
            addDisabledText: '',
        };

        this.load = this.load.bind(this);
        this.downloadHandler = this.downloadHandler.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.getRoute = this.getRoute.bind(this);
        this.updateIsActive = this.updateIsActive.bind(this);
        this.getHeaderButton = this.getHeaderButton.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    getHeaderButton() {
        const { collectionResponse } = this.state;
        const { match } = this.props;

        return Response.getLink(collectionResponse, 'create') ===
            null ? null : (
            <ButtonLink
                type="button"
                to={`${match.url}/add`}
                intent="primary"
                className="float-right"
                disabled={this.state.addDisabled}
                title={this.state.addDisabledText}
            >
                <Icon icon="plus-circle" />
                Add new User
            </ButtonLink>
        );
    }

    getColumns() {
        const { isClientBio } = this.props;

        let columns = [
            {
                name: 'Forename',
                property: 'forename',
                sortable: true,
                grow: 2,
            },
            { name: 'Surname', property: 'surname', sortable: true, grow: 2 },
            { name: 'Role', property: 'displayRole', sortable: false, grow: 2 },
            {
                name: 'Email',
                property: 'email',
                sortable: true,
                grow: 3,
                // hide: 'sm',
            },
            {
                name: 'Status',
                property: 'isActive',
                type: 'mapping',
                mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Last Login Date',
                property: 'lastSuccessfulLogin',
                type: 'datetime',
                sortable: false,
                grow: 3,
            },
        ];

        if (!isClientBio || isClientAdmin() || isAdmin()) {
            columns.push({
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return (
                        <div className="full-width flex-end">
                            {!Response.getLink(row, 'update-is-active') ? (
                                ''
                            ) : (
                                <Button
                                    type="button"
                                    intent={
                                        row.isActive ? 'warning' : 'success'
                                    }
                                    onClick={() => this.updateIsActive(row)}
                                >
                                    {row.isActive
                                        ? 'Make Inactive'
                                        : 'Make Active'}
                                </Button>
                            )}
                            &nbsp;
                            <ButtonLink
                                type="button"
                                to={this.getRoute(row)}
                                intent="primary"
                            >
                                <Icon icon="eye" />
                                Edit
                            </ButtonLink>
                        </div>
                    );
                },
            });
        }

        return columns;
    }

    render() {
        const { isLoading, filter, collectionResponse } = this.state;

        return (
            <div className="UserList">
                <List
                    title="User List"
                    columns={this.getColumns()}
                    load={(filter) => this.load(filter, true)}
                    collection={collectionResponse}
                    filter={filter}
                    defaultFilter={this.defaultFilters}
                    isLoading={isLoading}
                    onFilter={this.onFilter}
                    headerButton={this.getHeaderButton}
                    hideFilters={true}
                    downloadHandler={this.downloadHandler}
                />
            </div>
        );
    }

    getRoute(row, column) {
        return (
            Response.getLink(row, 'edit') && `${this.props.match.url}/${row.id}`
        );
    }

    clearFilters() {
        this.setState({
            filter: {
                ...this.defaultFilters,
                page: 1,
            },
        });
    }

    onFilter(filters, filterData) {
        this.setState({
            filter: {
                ...this.state.filterData,
                ...filterData,
            },
        });
    }

    load = async (params = {}, paramsOverride = false) => {
        if (this.state.isLoading) {
            return;
        }

        const { clientResponse, history, location } = this.props;

        const updatedClientResponse = await HTTP.get(
            Response.getLink(clientResponse, 'self'),
            { datagroup: 'details' }
        );

        let disabled = false;
        let disabledText = '';
        let subscriptionLevel = updatedClientResponse.data.subscriptionLevel;
        let numberActiveusers = updatedClientResponse.data.numberActiveusers;

        if (
            (subscriptionLevel === 'Silver' && numberActiveusers >= 10) ||
            (subscriptionLevel === 'Bronze' && numberActiveusers >= 5) ||
            (typeof subscriptionLevel === 'undefined' && numberActiveusers >= 5)
        ) {
            disabled = true;
            disabledText = `In order to add more users an upgrade to ${
                isClientAdmin() ? 'your' : "the client's"
            } subscription level is required`;
        }

        this.setState({
            addDisabled: disabled,
            addDisabledText: disabledText,
        });

        this.setState({ filter: params, isLoading: true });

        ClientService.loadClientUsers(clientResponse.id, params).then(
            (response) => {
                if (response) {
                    response.users.forEach((user) => {
                        let selectedRole = user.roles.filter(
                            (role) => role !== 'ROLE_USER'
                        )[0];
                        user.displayRole = CLIENT_ROLE_OPTIONS.filter(
                            (role) => role.value === selectedRole
                        )[0].label;
                    });
                    response.data = response.users;
                    this.setState({
                        collectionResponse: response,
                        isLoading: false,
                    });

                    return true;
                }

                toast.error('Unable to fetch Users');
                this.setState({ isLoading: false });

                return false;
            }
        );
    };

    downloadHandler = async (downloadParams) => {
        const { clientResponse } = this.props;
        const { filter } = this.state;

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            filter,
            downloadParams
        );

        console.log('state', this.state);

        await ClientService.downloadClientUsers(
            clientResponse.id,
            filterParams
        );

        toast.success('Client Users Downloaded');
    };

    updateIsActive = async (user) => {
        const isActiveLink = Response.getLink(user, 'update-is-active');
        const success = await HTTP.action(
            'patch',
            isActiveLink,
            {
                isActive: !user.isActive,
            },
            {},
            null,
            HTTP.handleFormErrors
        );

        if (success) {
            toast.success(
                `Successfully updated user to be ${
                    !user.isActive ? 'active' : 'inactive'
                }`
            );
            this.load();
        }
    };
}

export default UserList;
