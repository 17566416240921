import { Button, Tab, Tabs } from '@blueprintjs/core';
import { merge } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import {
    AssessmentTypeInstanceList,
    CertificationMemberships,
    CompanyRegions,
    CompanyServices,
    Contracts,
    Info,
    Insurances,
    Users,
} from 'components/company';
import CompanyClientsList from 'components/company/clients/CompanyClientsList';
import SuspendServiceProviderReasonDialog from 'components/dialogs/SuspendServiceProviderReasonDialog';
import { ButtonLink, Icon, ProtectedRoute } from 'components/elements';
import AddToSupplyChain from 'components/elements/supply-chain/add/AddToSupplyChain';
import { Loading } from 'components/elements/wrappers';
import ServiceProviderAddEdit from 'components/service-provider/ServiceProviderAddEdit';
import { isAdmin, isAssessorReviewer, isCompanyAdmin } from 'helpers/helpers';
import { HTTP, Response, Routing } from 'service';

import DeactivateServiceProviderDialog from './DeactivateServiceProviderDialog';
import ServiceProviderHeading from './ServiceProviderHeading';

import './index.scss';

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class ServiceProviderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            response: props.companyResponse || {},
            deactivateDialogIsOpen: false,
        };

        this.load = this.load.bind(this);
        this.removeSuspension = this.removeSuspension.bind(this);
        this.mergeCompanyResponse = this.mergeCompanyResponse.bind(this);
    }

    componentDidMount() {
        if (!this.props.companyResponse) {
            this.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.companyResponse !== prevProps.companyResponse) {
            this.setState({ response: this.props.companyResponse });
        }
    }

    mergeCompanyResponse(data = {}) {
        const { response } = this.state;
        if (this.props.mergeCompanyResponse !== undefined) {
            return this.props.mergeCompanyResponse(data);
        }
        this.setState({ response: merge({}, response, data) });
    }

    render() {
        const { isLoading, response } = this.state;
        const { location, match } = this.props;

        let tabId = Routing.getSelectedTabId(location.pathname, match.url);

        return (
            <div className="page ServiceProviderDetails">
                <div className="no-styling page-wrapper">
                    <div className="page-header">
                        <ServiceProviderHeading
                            name={response.registeredCompanyName}
                            county={response.county}
                            isSuspended={response.currentSupplyChainInstance?.isSuspended}
                            isActive={!!response.isActive}
                            isLoading={isLoading}
                        />
                        <div className="page-options">
                            {isAdmin() &&
                                response.isActive &&
                                Response.getLink(response, 'edit') !== null &&
                                !isLoading && (
                                    <DeactivateServiceProviderDialog
                                        companyResponse={response}
                                        onConfirm={this.load}
                                    />
                                )}

                            {/* edit company */}
                            {this.props.location.pathname.includes('info') &&
                            Response.getLink(response, 'edit') !== null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/edit`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Edit Company Details
                                </ButtonLink>
                            ) : null}

                            {/* edit regions */}
                            {this.props.location.pathname.includes('regions') &&
                            Response.getLink(response, 'edit') !== null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/regions/edit`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Edit Company Regions
                                </ButtonLink>
                            ) : null}

                            {/* edit services */}
                            {this.props.location.pathname.includes(
                                'services'
                            ) &&
                            Response.getLink(response, 'edit-services') !==
                                null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/services/edit`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Edit Company Services
                                </ButtonLink>
                            ) : null}

                            {/* add user */}
                            {this.props.location.pathname.includes('users') &&
                            Response.getLink(response, 'add-existing-user') !== null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/users/add-existing`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="plus-circle" />
                                    Add Existing User
                                </ButtonLink>
                            ) : null}

                            {this.props.location.pathname.includes('users') &&
                            Response.getLink(response, 'add-user') !== null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/users/add`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="plus-circle" />
                                    Add New User
                                </ButtonLink>
                            ) : null}

                            {/* add insurance */}
                            {this.props.location.pathname.includes(
                                'insurances'
                            ) &&
                            Response.getLink(response, 'add-insurance') !==
                                null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/insurances/add`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Add Insurance
                                </ButtonLink>
                            ) : null}

                            {/* add certification membership */}
                            {this.props.location.pathname.includes(
                                'certification-memberships'
                            ) &&
                            Response.getLink(
                                response,
                                'add-certification-membership'
                            ) !== null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/certification-memberships/add`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Add Certification/Membership
                                </ButtonLink>
                            ) : null}

                            {/* add contract */}
                            {this.props.location.pathname.includes(
                                'contracts'
                            ) &&
                            Response.getLink(response, 'add-contract') !==
                                null ? (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/contracts/add`}
                                    intent="primary"
                                    className="float-right"
                                >
                                    <Icon icon="pencil" />
                                    Add Contract
                                </ButtonLink>
                            ) : null}

                            {/* suspend company */}
                            {Response.getLink(response, 'suspend-company') && (
                                <SuspendServiceProviderReasonDialog
                                    company={response}
                                    reload={async () => {
                                        this.load(false);
                                    }}
                                />
                            )}

                            {/* un-suspend company */}
                            {Response.getLink(
                                response,
                                'remove-suspension'
                            ) && (
                                <Button
                                    type="button"
                                    text="Unsuspend"
                                    intent="primary"
                                    onClick={() => this.removeSuspension()}
                                />
                            )}

                            {response.id ? (
                                <AddToSupplyChain company={response} />
                            ) : null}
                        </div>
                    </div>
                    <Loading
                        isLoading={
                            isLoading || typeof response.id === 'undefined'
                        }
                    >
                        <Tabs
                            renderActiveTabPanelOnly={true}
                            selectedTabId={tabId}
                        >
                            <Tab id={'/info'}>
                                <Link to={`${match.url}/info`}>
                                    <Icon icon="info" />
                                    Company Details
                                </Link>
                            </Tab>
                            {isAssessorReviewer() || isCompanyAdmin() ? (
                                <Tab id={'/users'}>
                                    <Link to={`${match.url}/users`}>
                                        <Icon icon="users" />
                                        Users
                                    </Link>
                                </Tab>
                            ) : null}
                            {isAssessorReviewer() ? (
                                <Tab id={'/clients'}>
                                    <Link to={`${match.url}/clients`}>
                                        <Icon icon="users" />
                                        Clients
                                    </Link>
                                </Tab>
                            ) : null}
                            <Tab id={'/services'}>
                                <Link to={`${match.url}/services`}>
                                    <Icon icon="conveyor-belt-alt" />
                                    Services
                                </Link>
                            </Tab>
                            <Tab id={'/all-assessments'}>
                                <Link to={`${match.url}/all-assessments`}>
                                    <Icon icon="file-certificate" />
                                    Assessments
                                </Link>
                            </Tab>
                            <Tab id={'/regions'}>
                                <Link to={`${match.url}/regions?order=name`}>
                                    <Icon icon="map-marker" />
                                    Areas Covered
                                </Link>
                            </Tab>
                            {Response.getLink(response, 'insurances') !==
                            null ? (
                                <Tab id={'/insurances'}>
                                    <Link to={`${match.url}/insurances`}>
                                        <Icon icon="house-damage" />
                                        Insurances
                                    </Link>
                                </Tab>
                            ) : null}
                            {Response.getLink(
                                response,
                                'certification-memberships'
                            ) !== null && (
                                <Tab id={'/certification-memberships'}>
                                    <Link
                                        to={`${match.url}/certification-memberships`}
                                    >
                                        <Icon icon="file-certificate" />
                                        Certification &amp; Memberships
                                    </Link>
                                </Tab>
                            )}
                            {Response.getLink(response, 'view-contracts') !==
                                null && (
                                <Tab id={'/contracts'}>
                                    <Link to={`${match.url}/contracts`}>
                                        <Icon icon="file-certificate" />
                                        Contracts
                                    </Link>
                                </Tab>
                            )}
                        </Tabs>

                        <div className="TabContent">
                            <Switch>
                                <ProtectedRoute
                                    path={`${match.url}/info`}
                                    component={Info}
                                    companyResponse={response}
                                    reloadData={this.load}
                                    mergeCompanyResponse={
                                        this.mergeCompanyResponse
                                    }
                                    exact
                                />
                                <ProtectedRoute
                                    path={`${match.url}/info/edit`}
                                    component={ServiceProviderAddEdit}
                                    companyResponse={response}
                                    mergeCompanyResponse={
                                        this.mergeCompanyResponse
                                    }
                                    exact
                                />
                                <ProtectedRoute
                                    path={`${match.url}/regions`}
                                    component={CompanyRegions}
                                    companyResponse={response}
                                />
                                <ProtectedRoute
                                    path={`${match.url}/services`}
                                    component={CompanyServices}
                                    companyResponse={Response.getUpdatableResponse(
                                        response
                                    )}
                                    mergeCompanyResponse={
                                        this.mergeCompanyResponse
                                    }
                                />
                                <ProtectedRoute
                                    path={`${match.url}/clients`}
                                    component={CompanyClientsList}
                                    companyResponse={Response.getUpdatableResponse(
                                        response
                                    )}
                                />
                                <ProtectedRoute
                                    path={`${match.url}/all-assessments`}
                                    component={AssessmentTypeInstanceList}
                                    companyResponse={response}
                                    filter={{ companyId: response.id }}
                                />

                                <ProtectedRoute
                                    path={`${match.url}/users`}
                                    component={Users}
                                    companyResponse={response}
                                    reloadData={this.load}
                                />

                                <ProtectedRoute
                                    path={`${match.url}/insurances`}
                                    component={Insurances}
                                    companyResponse={response}
                                />

                                <ProtectedRoute
                                    path={`${match.url}/certification-memberships`}
                                    component={CertificationMemberships}
                                    companyResponse={response}
                                />

                                <ProtectedRoute
                                    path={`${match.url}/contracts`}
                                    component={Contracts}
                                    companyResponse={response}
                                />

                                <Redirect to={`${match.url}/info`} />
                            </Switch>
                        </div>
                    </Loading>
                </div>
            </div>
        );
    }

    load(showLoader = true) {
        if (this.state.isLoading) {
            return;
        }

        const companyId = this.state.id ?? this.props.companyResponse?.id;

        if (!companyId) {
            toast.error("Could not load company information");
            return;
        }

        this.setState({ isLoading: true && showLoader });

        HTTP.get(`/companies/${companyId}`, { datagroup: 'details' }).then(
            (response) => {
                if (response) {
                    this.setState({
                        isLoading: false,
                        response: response.data,
                    });
                    this.props.updatingBreadcrumbResolves({
                        serviceProvider: {
                            registeredCompanyName:
                                response.data.registeredCompanyName,
                        },
                    });

                    return true;
                }

                toast.error('Unable to fetch Service Provider details');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }

    removeSuspension() {
        const { response } = this.state;

        const removalLink = Response.getLink(response, 'remove-suspension');
        if (removalLink) {
            HTTP.patch(removalLink)
                .then(() => {
                    this.load(false);
                    toast.success('Successfully unsuspended company');
                })
                .catch(() => {
                    toast.error('Unable to unsuspend company');
                });
        }
    }
}

export default connect(null, mapDispatchToProps)(ServiceProviderDetails);
