import { Tab, Tabs } from '@blueprintjs/core';
import JWT from '@elb/jsonwebtoken';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Routing } from 'service';

import AdministratorDashboard from 'components/dashboard/AdministratorDashboard';
import ClientDashboard from 'components/dashboard/ClientDashboard';
import CompanyDashboard from 'components/dashboard/CompanyDashboard';

class User extends Component {
    render() {
        return <>User Dashboard</>;
    }
}

class Dashboard extends Component {
    dashboards = [];

    constructor(props) {
        super(props);

        this.state = {
            dashboards: [],
        };

        this.determineDashboards = this.determineDashboards.bind(this);
        this.getTabOrTabs = this.getTabOrTabs.bind(this);
        this.getTabsFromDashboards = this.getTabsFromDashboards.bind(this);
    }

    componentDidMount() {
        this.determineDashboards();
    }

    determineDashboards() {
        const dashboards = [];

        if (
            JWT.hasRole('ROLE_ADMINISTRATOR') ||
            JWT.hasRole('ROLE_PROSURE_CLIENT_SERVICES') ||
            JWT.hasRole('ROLE_REGISTRATIONS_RENEWALS') ||
            JWT.hasRole('ROLE_ASSESSMENT_ASSESSOR') ||
            JWT.hasRole('ROLE_ASSESSMENT_REVIEWER')
        ) {
            dashboards.push({
                route: '/administrator',
                label: 'Administrator',
                component: () => {
                    return <AdministratorDashboard />;
                },
            });
        }

        if (
            JWT.hasRole('ROLE_CLIENT_USER') ||
            JWT.hasRole('ROLE_CLIENT_ADMINISTRATOR') ||
            JWT.hasRole('ROLE_CLIENT_VIEW_ONLY') ||
            JWT.hasRole('ROLE_CLIENT_ONBOARDING')
        ) {
            dashboards.push({
                route: '/client',
                label: 'Client',
                component: ClientDashboard,
            });
        }

        if (
            JWT.hasRole('ROLE_COMPANY_USER') ||
            JWT.hasRole('ROLE_COMPANY_ADMINISTRATOR')
        ) {
            dashboards.push({
                route: '/company',
                label: 'Service Provider',
                component: CompanyDashboard,
            });
        }

        if (dashboards.length === 0 && JWT.hasRole('ROLE_USER')) {
            dashboards.push({
                route: '/',
                label: '',
                acomponent: () => {
                    return <>User</>;
                },
                component: User,
            });
        }

        this.setState({ dashboards: dashboards });
    }

    getTabOrTabs() {
        const { location, match } = this.props;
        let tabId = Routing.getSelectedTabId(location.pathname, match.url);
        if (
            !tabId &&
            this.state &&
            this.state.dashboards &&
            this.state.dashboards[0]
        ) {
            tabId = this.state.dashboards[0].route;
        }

        if (this.state.dashboards.length === 1) {
            const { component: Component } = this.state.dashboards[0];

            return <Component />;
        }

        return (
            <div>
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    {this.getTabsFromDashboards()}
                </Tabs>
            </div>
        );
    }

    getTabsFromDashboards() {
        const { match } = this.props;
        const { dashboards } = this.state;

        return dashboards.map((dashboard, index) => {
            const { component: Component } = dashboard;

            return (
                <Tab
                    id={dashboard.route}
                    key={dashboard.route}
                    panel={<Component />}
                >
                    <Link to={`${match.url}${dashboard.route}`}>
                        {dashboard.label}
                    </Link>
                </Tab>
            );
        });
    }

    render() {
        return <>{this.getTabOrTabs()}</>;
    }
}

export default Dashboard;
