import { H1 } from '@blueprintjs/core';

import { ButtonLink, Icon } from 'components/elements';
import { parseDateFormat, toMoney } from 'helpers/helpers';
import {
    CampaignComponentProps,
    CampaignStatusMapping,
} from 'interface/Client/Campaign';
import { Response } from 'service';

const CampaignDetails = (props: CampaignComponentProps) => {
    const { campaign, match } = props;

    return (
        <div className="CampaignDetails">
            <H1 className="clearfix text-right">
                {Response.getLink(campaign, 'edit') === null ? null : (
                    <ButtonLink
                        type="button"
                        to={`${match.url}/edit`}
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="pencil" />
                        Edit
                    </ButtonLink>
                )}
                {Response.getLink(campaign, 'add-invitee') && (
                    <ButtonLink
                        type="button"
                        to={`/clients/campaigns/list/${campaign.id}/invitees/add`}
                        intent="primary"
                        className="float-right me-2"
                    >
                        <Icon icon="plus-circle" />
                        Add Invitee
                    </ButtonLink>
                )}
            </H1>
            <dl className="horizontal">
                <dt>Client</dt>
                <dd>{Response.getLinkAttribute(campaign, 'client', 'name')}</dd>
                <dt>Campaign Name</dt>
                <dd>{campaign.name}</dd>
                <dt>Description</dt>
                <dd>{campaign.description}</dd>
                <dt>Campaign Start Date</dt>
                <dd>{parseDateFormat(campaign.startDate, 'DD/MM/YYYY')}</dd>
                <dt>State</dt>
                <dd>{CampaignStatusMapping[campaign.status]}</dd>
                <dt>Invitees</dt>
                <dd>{campaign.numberOfInvitees}</dd>
                <dt>Declined</dt>
                <dd>
                    <span>{campaign.numberOfInviteesDeclined || 0} </span>
                    <span>
                        (
                        {Math.floor(
                            (parseInt(campaign.numberOfInviteesDeclined) /
                                parseInt(campaign.numberOfInvitees)) *
                                100
                        ) || 0}
                        %)
                    </span>
                </dd>
                <dt>Joined Vantify Supply Chain</dt>
                <dd>
                    <span>{campaign.numberOfInviteesJoined || 0} </span>
                    <span>
                        (
                        {Math.floor(
                            (parseInt(campaign.numberOfInviteesJoined) /
                                parseInt(campaign.numberOfInvitees)) *
                                100
                        ) || 0}
                        %)
                    </span>
                </dd>
                <dt>Vantify Pre-Qualified</dt>
                <dd>{campaign.ssipPreQualCount?.toString()} Purchased ({toMoney(campaign.ssipPreQualTotalExVat)})</dd>
                <dt>Vantify SSIP Deem-to-Satisfy</dt>
                <dd>{campaign.ssipDtsCount?.toString()} Purchased ({toMoney(campaign.ssipDtsTotalExVat)})</dd>
                <dt>Vantify SSIP Verified</dt>
                <dd>{campaign.ssipVerifiedCount?.toString()} Purchased ({toMoney(campaign.ssipVerifiedTotalExVat)})</dd>
                <dt>Supply Chain Fee - Vantify</dt>
                <dd>{toMoney(campaign.totalSupplyChainRevenueExVat)}</dd>
            </dl>
        </div>
    );
};

export default CampaignDetails;
