import { Button, Card, H3 } from '@blueprintjs/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { MultiLevelCheckboxArray } from 'components/elements';
import { SUPPORT_EMAIL } from 'constants/general';

const mapStateToProps = (state) => {
    return {
        form: state.auth.form,
    };
};

const mapDispatchToProps = {};

const ServicesOffered = (props) => {
    const { form, errorMessage, closePanel, triggerHeightChange } = props;

    const getSelected = (field) => {
        return field && field.length
            ? field.reduce((total, next) => {
                  if (typeof total === 'object') {
                      const childrenSelected = getSelected(total.children);
                      total = total.checked ? 1 : 0;
                      total += childrenSelected;
                  }
                  total += next.checked ? 1 : 0;
                  return total + getSelected(next.children);
              }, 0)
            : 0;
    };

    const getTotal = (field) => {
        return field && field.length
            ? field.reduce((total, next) => {
                  if (typeof total === 'object') {
                      total = 1 + getTotal(total.children);
                  }
                  return total + 1 + getTotal(next.children);
              }, 0)
            : 0;
    };

    const [allSelected, setSelected] = useState(getSelected(form.services));

    const updateCheckboxTotals = () => {
        setSelected(getSelected(form.services));
        triggerHeightChange();
    };

    return (
        <>
            <div className="header small">
                <div className="header-desc">
                    <H3>Tailor your experience</H3>
                    <p>
                        To get the most out of Vantify Supply Chain, we need to know a
                        little bit more about what you do.
                    </p>
                    <p>
                        Firstly, what are the services you offer? Once
                        registered, you can add more detail to these.
                    </p>
                    <p className="mb-4">
                        If you can not see a suitable category, please email us
                        on{' '}
                        <a href={`mailto:${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}
                        </a>
                    </p>
                </div>
                <img
                    className="header-logo"
                    src="/assets/images/logo.png"
                    alt="Vantify Supply Chain logo"
                />
            </div>
            <div className="body">
                {!errorMessage ? null : (
                    <Card className="alert">{errorMessage}</Card>
                )}
                <MultiLevelCheckboxArray
                    fields={form.services}
                    onUpdate={updateCheckboxTotals}
                />
                <div className="flex-between mt-5">
                    <Button
                        type="button"
                        text="Previous"
                        className="rounded"
                        onClick={closePanel}
                        large={true}
                    />
                    <span>
                        {allSelected} out of {getTotal(form.services)} selected
                    </span>
                    <Button
                        type="submit"
                        text="Next"
                        className="rounded"
                        intent="primary"
                        large={true}
                    />
                </div>
            </div>
            <div className="footer box-footer flex-start">
                <Link to={props.cancelUrl ?? '/login'}>Cancel</Link>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesOffered);
