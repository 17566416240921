import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { List } from "components/elements";
import { NONE_SELECTED_OPTION } from "constants/none-selected";
import { AssessmentTypeInstanceData, AssessmentTypeInstanceListFilter, ListSearchFilter } from "interface";
import { AssessmentTypeService, InstanceService, Response } from "service";

interface AssessmentRenewedListFilters {
    rootAssessment: ListSearchFilter;
    renewedAssessment: ListSearchFilter;
    companyName: ListSearchFilter;
}

const AssessmentTypeInstanceRenewedList = (props: any) => {
    const { collection, load, filter, isLoading } = props;

    const [searchFilters, setSearchFilters] = useState<AssessmentRenewedListFilters>({
        rootAssessment: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Assessment',
            key: 'rootAssessment',
            options: [],
        },
        companyName: {
            type: 'text',
            label: 'Service Provider Name',
            key: 'companyName',
            placeholder: '',
        },
        renewedAssessment: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Renewed Assessment',
            key: 'renewedAssessment',
            options: [],
        },
    });

    useEffect(() => {
        (async () => {
            const searchFiltersCopy = { ...searchFilters };

            const assessmentOptions = await AssessmentTypeService.getAssessmentTypeListForDropdown();

            searchFiltersCopy.rootAssessment.options = assessmentOptions;
            searchFiltersCopy.renewedAssessment.options = assessmentOptions;

            setSearchFilters(searchFiltersCopy);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        order: 'expiryDate',
        renewalStatus: 'renewed'
    } as AssessmentTypeInstanceListFilter;

    const companyRoute = (row: AssessmentTypeInstanceData) => {
        return `/search/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            type: 'string',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
            grow: 4,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Completed Date',
            type: 'datetime',
            property: 'completedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Expiry Date',
            type: 'datetime',
            property: 'expiryDate',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Renewed Assessment Name',
            type: 'string',
            property: 'renewedByAssessmentName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Date Of Purchase',
            type: 'datetime',
            property: 'purchasedAt',
            sortable: true,
            grow: 2,
        },
    ];

    const onDownload = async () => {
        await InstanceService.downloadList(filter, 'renewed-assessments')
            .then(() => {
                toast.success('Assessment Type List Downloaded');
            })
            .catch(() => {
                toast.error('Assessment Type List Could Not Be Downloaded');
            });
    };

    return (
        <div className="renewedAssessmentsList">
            <List
                title="Renewed Assessments"
                columns={columns}
                load={(filter) =>
                    load({ ...defaultSpecificFilter, ...filter })
                }
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                filters={searchFilters}
                defaultFilter={defaultSpecificFilter}
                downloadHandler={onDownload}
            />
        </div>
    );
}

export default AssessmentTypeInstanceRenewedList;
