import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core';
import { AxiosResponse } from 'axios';
import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { HTMLSelect } from 'components/elements/index';
import { AssessmentTypeInstanceData } from 'interface';
import {
    REFUND_REASON_OPTIONS,
    RefundPaymentData,
    RefundReason,
} from 'interface/Payment/RefundPayment';
import { HTTP, Response } from 'service';

interface RefundPaymentDialogProps {
    assessmentType: AssessmentTypeInstanceData;
    reload: { (): void };
    onSuccess?: Function;
    onClose?: Function;
    showButton?: boolean;
    isOpen?: boolean;
}

const RefundPaymentDialog = (props: RefundPaymentDialogProps) => {
    const {
        assessmentType,
        reload,
        onClose,
        onSuccess,
        showButton = true,
        isOpen = false,
    } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [refundPayment, setRefundPayment] = useState<RefundPaymentData>(
        {} as RefundPaymentData
    );

    useEffect(() => {
        setIsDialogOpen(isOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    const handleClose = () => {
        setRefundPayment({} as RefundPaymentData);
        setIsDialogOpen(false);

        if (onClose) {
            onClose();
        }
    };

    const handleOnSuccess = () => {
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
    };

    const submitRefundPayment = async (event: FormEvent<HTMLFormElement>) => {
        event.stopPropagation();
        event.preventDefault();

        const refundLink = Response.getLink(assessmentType, 'refund');
        if (refundLink) {
            await HTTP.patch(refundLink, refundPayment)
                .then((response: AxiosResponse | void) => {
                    if (response) {
                        handleClose();
                        handleOnSuccess();
                        reload();
                    }
                })
                .catch((error: any) => {
                    const { response } = error;

                    if (response?.data && response.data.length > 0) {
                        toast.error(response.data[0].message);
                    }
                });
        } else {
            handleClose();
        }
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    return (
        <>
            {showButton && (
                <Button
                    onClick={openDialog}
                    intent="warning"
                    title="Refund Payment"
                >
                    Refund
                </Button>
            )}
            <Dialog
                title="Refund Payment"
                className="refund-modal"
                onClose={handleClose}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isDialogOpen}
            >
                <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) =>
                        submitRefundPayment(event)
                    }
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label="Refund Reason"
                            inline={true}
                            className="form-fill required"
                            labelFor="reason"
                        >
                            <HTMLSelect
                                id="reason"
                                fill={true}
                                value={refundPayment.reason}
                                onChange={(
                                    event: FormEvent<HTMLSelectElement>
                                ) => {
                                    const { value } =
                                        event.target as HTMLInputElement;

                                    setRefundPayment({
                                        ...refundPayment,
                                        reason: parseInt(value) as RefundReason,
                                    });
                                }}
                                options={REFUND_REASON_OPTIONS}
                                required
                            />
                        </FormGroup>
                        {refundPayment.reason === RefundReason.OTHER && (
                            <FormGroup
                                label="Details"
                                inline={true}
                                className="form-fill required"
                                labelFor="Details"
                            >
                                <InputGroup
                                    placeholder="Details"
                                    minLength={1}
                                    maxLength={255}
                                    value={refundPayment.detail}
                                    onChange={(
                                        e: FormEvent<HTMLInputElement>
                                    ) => {
                                        const { value } =
                                            e.target as HTMLInputElement;
                                        setRefundPayment({
                                            ...refundPayment,
                                            detail: value,
                                        });
                                    }}
                                />
                            </FormGroup>
                        )}

                        <FormGroup className={Classes.DIALOG_FOOTER}>
                            <Button
                                type="button"
                                className="float-left"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="success"
                                className="float-right"
                            >
                                Refund
                            </Button>
                        </FormGroup>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default RefundPaymentDialog;
