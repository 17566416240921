import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Background } from 'components/elements/layout';
import ExhibitionSignUp from "components/pre-auth/ExhibitionSignUp";
import ForgottenPassword from 'components/pre-auth/ForgottenPassword';
import Login from 'components/pre-auth/Login';
import PasswordReset from 'components/pre-auth/PasswordReset';
import RegisterWizard from 'components/pre-auth/RegisterWizard';

class PreAuthContainer extends Component
{
    render() {
        return (
            <div className="PreAuthContainer">
                <Switch>
                    <Route path="/login" component={ Login } exact />
                    <Route path="/register" component={ RegisterWizard } />
                    <Route path="/forgotten-password" component={ ForgottenPassword } />
                    <Route path="/password-reset" component={ PasswordReset } />

                    <Route path="/register-exhibitions" component={ RegisterWizard } />
                    <Route path="/signup-exhibitions" component={ ExhibitionSignUp } />

                    <Redirect from="/" to="/login"/>
                 </Switch>
                 <Background preAuth/>
            </div>
        );
    }
}

export default PreAuthContainer;
