import { H1, H3 } from '@blueprintjs/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LoadingIndicator from 'components/elements/LoadingIndicator';
import { HTTP, NumberFormatting, Response } from 'service';

import 'components/elements/payment/purchase.scss';
import { SUPPORT_EMAIL } from 'constants/general';

const Payment = (props) => {
    const {
        instance,
        total,
        discount,
        logo,
        title,
        confirmationPage,
        purchaseUrl,
        confirmUrl,
    } = props;

    const rootResponse = useSelector((state) => state.root.response);

    const [succeeded, setSucceeded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [intentId, setIntentId] = useState('');

    const [billingDetails, setBillingDetails] = useState({
        email: rootResponse.user.email,
        phone: rootResponse.user.phone,
        name: rootResponse.user.forename + ' ' + rootResponse.user.surname,
    });

    const stripe = useStripe();
    const elements = useElements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);

            HTTP.post(
                purchaseUrl,
                discount && {
                    _links: {
                        discount: { href: Response.getLink(discount, 'self') },
                    },
                }
            ).then((response) => {
                if (discount && discount.discount === 100) {
                    toast.success('Thank you for your purchase!');
                    confirmationPage({ response });

                    return false;
                }

                setClientSecret(response.data.secret);
                setIntentId(response.data.id);
            });
        }
    });

    const cardStyle = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#c4f0ff',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#87bbfd',
                },
            },
            invalid: {
                iconColor: '#ffc7ee',
                color: '#ffc7ee',
            },
        },
    };

    const toMoney = (price) => {
        price = parseFloat(price);
        if (isNaN(price)) {
            return '£0.00';
        }

        return NumberFormatting.formatCurrency(price, 'GBP');
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
            receipt_email: billingDetails.email,
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);

            // trigger api check to update local payment record and create instance if payment is successfull on stripe
            HTTP.post(confirmUrl, {
                intentId: intentId,
                billingName: billingDetails.name,
                billingEmail: billingDetails.email,
                billingPhone: billingDetails.phone,
            }).then((res) => {
                setProcessing(false);

                if (res.data.status === 'succeeded') {
                    setSucceeded(true);
                    toast.success('Thank you for your purchase!');
                    confirmationPage(res.data);
                } else {
                    setSucceeded(false);
                    setError(
                        `Payment failed - there was an issue with your payment`
                    );
                }

                return res;
            });

            return true;
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setError(event.error ? event.error.message : '');
    };

    return (
        <div className="page">
            <div className="page-wrapper no-styling Payment">
                <H1 className="clearfix">{title}</H1>

                <div className="flex">
                    <div className="flex-1">
                        {!(
                            instance._links &&
                            Response.getLink(instance, logo)
                        ) ? (
                            <></>
                        ) : (
                            <img
                                className="badge-img"
                                alt={instance._links[logo].title}
                                title={instance._links[logo].title}
                                src={
                                    process.env.REACT_APP_BASE_URL +
                                    instance._links[logo].publicUrl
                                }
                            />
                        )}
                    </div>
                    <div className="flex-5">
                        {!total > 0 ? null : (
                            <div className="payment-wrapper">
                                <form
                                    className="payment-form"
                                    id="payment-form"
                                    onSubmit={handleSubmit}
                                >
                                    <H3>Billing Details</H3>

                                    <fieldset className="form-group">
                                        <div className="form-row">
                                            <label
                                                htmlFor="name"
                                                className="form-row-label"
                                            >
                                                Name
                                            </label>
                                            <input
                                                className="form-row-input"
                                                id="name"
                                                key="name"
                                                required
                                                type="text"
                                                placeholder="Name"
                                                value={billingDetails.name}
                                                onChange={(e) => {
                                                    setBillingDetails({
                                                        ...billingDetails,
                                                        name: e.target
                                                            .value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label
                                                htmlFor="email"
                                                className="form-row-label"
                                            >
                                                Email
                                            </label>
                                            <input
                                                className="form-row-input"
                                                id="email"
                                                key="email"
                                                type="email"
                                                required
                                                placeholder="Email"
                                                value={billingDetails.email}
                                                onChange={(e) => {
                                                    setBillingDetails({
                                                        ...billingDetails,
                                                        email: e.target
                                                            .value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label
                                                htmlFor="phone"
                                                className="form-row-label"
                                            >
                                                Phone
                                            </label>
                                            <input
                                                className="form-row-input"
                                                id="phone"
                                                key="phone"
                                                type="tel"
                                                placeholder="Phone"
                                                value={billingDetails.phone}
                                                onChange={(e) => {
                                                    setBillingDetails({
                                                        ...billingDetails,
                                                        phone: e.target
                                                            .value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </fieldset>

                                    <H3>Payment Details</H3>
                                    <fieldset className="form-group">
                                        <div className="form-row">
                                            <CardElement
                                                id="card-element"
                                                options={cardStyle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </fieldset>

                                    {error && (
                                        <div
                                            className="form-card error"
                                            role="alert"
                                        >
                                            {error}
                                        </div>
                                    )}

                                    {succeeded && (
                                        <p
                                            className={
                                                succeeded
                                                    ? 'result-message'
                                                    : 'result-message hidden'
                                            }
                                        >
                                            Payment succeeded.
                                        </p>
                                    )}

                                    {!stripe && (
                                        <div
                                            className="form-card error"
                                            role="alert"
                                        >
                                            Stripe could not be loaded!
                                            Please contact support to
                                            continue your purchase.
                                        </div>
                                    )}
                                    <div
                                        className="form-card warning"
                                        role="alert"
                                    >
                                        Payments could take up to 1 hour to
                                        process. If you have any concerns,
                                        please contact support at &nbsp;
                                        <a href={`mailto:${SUPPORT_EMAIL}`}>
                                            {SUPPORT_EMAIL}
                                        </a>
                                    </div>

                                    {processing && (
                                        <div
                                            className="form-card warning"
                                            role="alert"
                                        >
                                            Purchase is processing. Please
                                            wait while we retrieve the
                                            response from our payment
                                            gateway partner.
                                            <br />
                                            <br />
                                            Please do not navigate away from
                                            the page, or we will not be able
                                            to record your purchase.
                                        </div>
                                    )}

                                    <button
                                        className={`submit-button ${
                                            error
                                                ? 'submit-button--error'
                                                : ''
                                        }`}
                                        type="submit"
                                        disabled={processing || !stripe}
                                    >
                                        {processing && (
                                            <LoadingIndicator size="2" />
                                        )}
                                        Pay {toMoney(total)}
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment;
