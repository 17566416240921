import { Button, FormGroup, H4, InputGroup } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updateRegisterForm } from 'action/auth';
import CampaignService from 'service/Client/CampaignService';

const ReferralCode = (props) => {
    const { nextPage, triggerHeightChange } = props;
    const store = useStore();
    const [form, setForm] = useState({
        ...store.getState().auth.form,
        referralCode: '',
    });
    const [showForm, setShowForm] = useState(false);
    const [error, setError] = useState(false);

    const updateFormData = (referralCode) => {
        let formData = {
            ...store.getState().auth.form,
            referralCode,
        };
        store.dispatch(updateRegisterForm(formData));
        setForm(formData);
    };

    const verifyCode = async () => {
        const valid = await CampaignService.verifyReferralCode(
            form.referralCode
        );

        if (valid) {
            toast.success('Code validated successfully');
            nextPage();
        } else {
            setError(true);
            toast.error('Invalid or expired referral code submitted');
        }
    };

    useEffect(() => {
        triggerHeightChange();
    }, [showForm, triggerHeightChange]);

    return (
        <>
            <div className="header">
                <img
                    className="header-logo"
                    src="/assets/images/logo.png"
                    alt="Vantify Supply Chain logo"
                />
            </div>

            <div className="body">
                <div className="body-desc">
                    <H4>Do you have an invitation referral code?</H4>
                    <span>
                        If you have been sent a referral code by a client, you
                        must enter it to be invited to your clients' supply
                        chain.
                    </span>
                </div>
                <div className="py-5 px-4">
                    <div className="full-width flex pb-4 decisions">
                        <div className="decision">
                            <Button
                                type="button"
                                text="No"
                                className="rounded"
                                large={true}
                                onClick={nextPage}
                            />
                            <span>I do not have a referral code</span>
                        </div>
                        <div className="decision">
                            <Button
                                type="button"
                                text="Yes"
                                className="rounded"
                                onClick={() => setShowForm(true)}
                                intent="primary"
                                large={true}
                            />
                            <span>I do have a referral code</span>
                        </div>
                    </div>
                    {showForm && (
                        <>
                            <FormGroup className="form-fill input-with-error">
                                <InputGroup
                                    id="referralCode"
                                    placeholder="Referral Code"
                                    className={error ? 'error' : ''}
                                    value={form.referralCode}
                                    onChange={(e) =>
                                        updateFormData(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                        error && setError(false);
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            verifyCode();
                                        }
                                    }}
                                    pattern="[a-zA-Z0-9]+"
                                    minLength={2}
                                    maxLength={20}
                                />
                                {error && (
                                    <div className="error-message" role="alert">
                                        This is not a valid referral code.
                                    </div>
                                )}
                            </FormGroup>
                        </>
                    )}
                </div>
                {showForm && (
                    <div className="px-4 float-right">
                        <Button
                            type="button"
                            text="Click to verify code"
                            intent="primary"
                            className="rounded"
                            onClick={verifyCode}
                        />
                    </div>
                )}
            </div>
            <div className="footer box-footer flex-start">
                <Link to={props.cancelUrl ?? '/login'}>Cancel</Link>
            </div>
        </>
    );
};

export default ReferralCode;
