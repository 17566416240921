import { Editor } from '@tinymce/tinymce-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

type HtmlEditorProps = {
    onChange: Function;
    content?: string;
};

function HtmlEditor(props: HtmlEditorProps) {
    const editorRef = useRef<any>(null);
    const [isInit, setIsInit] = useState(true);
    const [initContent, setInitContent] = useState(props.content);

    useEffect(() => {
        if (!isInit || props.content === '') {
            return;
        }

        setIsInit(false);
        setInitContent(props.content);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content]);

    return (
        <>
            <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                // saved for future enhancement, possibly
                // tinymceScriptSrc={
                //     process.env.PUBLIC_URL + '/assets/tinyMCE/tinymce.min.js'
                // }
                ref={editorRef}
                initialValue={initContent ?? ''}
                init={{
                    height: 500,
                    width: '100%',
                    menubar: 'format',
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'help',
                        'wordcount',
                        'code',
                    ],
                    toolbar:
                        'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:13px }',
                    init_instance_callback: function (editor) {
                        editor.on('KeyUp', function (e: KeyboardEvent) {
                            e.stopPropagation();
                            typeof props.onChange === 'function' &&
                                props.onChange(editor.getContent());
                        });

                        editor.on('Change', function (e: ChangeEvent) {
                            e.stopPropagation();

                            typeof props.onChange === 'function' &&
                                props.onChange(editor.getContent());
                        });
                    },
                }}
            />
        </>
    );
}

export default HtmlEditor;
