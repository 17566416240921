import { H3 } from '@blueprintjs/core';
import { Loading } from 'components/elements/wrappers';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import CompanyRating from 'components/company/info/CompanyRating';
import DashboardCard from 'components/dashboard/DashboardCard';
import DashboardLogoCard from 'components/dashboard/DashboardLogoCard';
import { CompanyService, HTTP, Response } from 'service';
import DashboardTaskCard from './DashboardTaskCard';

import './company-dashboard.scss';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
const mapDispatchToProps = {};

class CompanyDashboard extends Component {
    companyInterval;
    stateInterval;

    constructor(props) {
        super(props);

        this.state = {
            isLoadingState: false,
            isLoadingDashboardStats: false,
            isLoadingProsureAssessments: false,
            isLoadingSupplyChain: false,
            prosureAssessment: undefined,
            prosurePreQualifiedAssessment: undefined,
            assessmentsCnt: 0,
            insurancesCnt: 0,
            certificationsCnt: 0,
            taskCnt: 0,
            activeSupplyChainsCnt: 0,
            pendingInvitationsCnt: 0,
        };

        this.loadAll = this.loadAll.bind(this);
        this.loadCompany = this.loadCompany.bind(this);
        this.loadSupplyChain = this.loadSupplyChain.bind(this);
        this.loadState = this.loadState.bind(this);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillUnmount() {
        window.clearInterval(this.companyInterval);
    }

    render() {
        const {
            isLoadingCompany,
            isLoadingState,
            isLoadingDashboardStats,
            prosureAssessment,
            prosurePreQualifiedAssessment,
            companyResponse,
        } = this.state;

        const companyRating = Response.getLinkAttribute(
            companyResponse,
            'rating',
            'rating'
        );

        const allRatings = companyResponse?._links?.rating;

        return (
            <div className={'DashboardPage company'}>
                <Loading isLoading={isLoadingCompany}>
                    <DashboardTaskCard
                        isLoading={isLoadingState}
                        taskCount={this.state.taskCnt}
                    />
                    <DashboardCard
                        className="company-rating-card"
                        isLoading={isLoadingCompany}
                        title="Vantify Supply Chain Company Score"
                    >
                        <CompanyRating
                            rating={companyRating}
                            allRatings={allRatings}
                            large={true}
                        />
                    </DashboardCard>
                    <DashboardLogoCard
                        isLoading={isLoadingState}
                        companyResponse={companyResponse}
                    />
                    <DashboardCard
                        isLoading={false}
                        title="Vantify Supply Chain Key Assessments"
                        moreText="Go to my assessments..."
                        link="/shop"
                    >
                        {this.prosureKeyAssessments(
                            prosureAssessment,
                            prosurePreQualifiedAssessment
                        )}
                    </DashboardCard>
                    <DashboardCard
                        isLoading={isLoadingDashboardStats}
                        title="Supply Chain Summary"
                    >
                        {this.supplyChainStats()}
                    </DashboardCard>
                    <DashboardCard
                        isLoading={isLoadingDashboardStats}
                        title="Account Status"
                        moreText="Go to Company Bio..."
                        link="/company/info"
                    >
                        {this.accountStats()}
                    </DashboardCard>
                </Loading>
            </div>
        );
    }

    isAssessmentDashboardPanelActive(assessment) {
        return (
            assessment !== undefined && assessment.hasOwnProperty('completedAt')
        );
    }

    accountStats() {
        return (
            <div className="account-stats">
                <div>
                    <H3>{this.state.assessmentsCnt}</H3>
                    <p>Assessments In-Progress</p>
                </div>
                <div>
                    <H3>{this.state.insurancesCnt}</H3>
                    <p>Active Insurances</p>
                </div>
                <div>
                    <H3>{this.state.certificationsCnt}</H3>
                    <p>Other Certifications / Memberships</p>
                </div>
            </div>
        );
    }

    supplyChainStats() {
        return (
            <div className="supply-chain-summary-stats">
                <div>
                    <Link
                        className="dashboard-card-link"
                        to={'/supply-chains/active'}
                    >
                        <H3>{this.state.activeSupplyChainsCnt}</H3>
                        <p>Active Supply Chains</p>
                    </Link>
                </div>
                <div>
                    <Link
                        className="dashboard-card-link"
                        to={'/supply-chains/pending'}
                    >
                        <H3>{this.state.pendingInvitationsCnt}</H3>
                        <p>Pending Invitations</p>
                    </Link>
                </div>
            </div>
        );
    }

    prosureKeyAssessments(prosureAssessment, prosurePreQualified) {
        return (
            <div className="prosure-key-assessments-panel">
                <div
                    className={`bp3-card bp3-elevation-0 large ${
                        !this.isAssessmentDashboardPanelActive(
                            prosurePreQualified
                        ) && 'not-active'
                    }`}
                >
                    <div className="logo">
                        <img
                            className={`prosure-pre-qualified-logo`}
                            src={'/assets/images/badges/vantify-prequal.svg'}
                            alt={'Vantify Pre Qualified Logo'}
                        />
                    </div>
                    <div className={'content'}>
                        <h2>Vantify Pre-Qualified</h2>
                        {this.isAssessmentDashboardPanelActive(
                            prosurePreQualified
                        ) ? (
                            <p>
                                Last Completed Date:{' '}
                                {moment(prosurePreQualified.completedAt).format(
                                    'Do MMMM YYYY'
                                )}
                            </p>
                        ) : (
                            <div className={'moreDetails'}>Purchase Here</div>
                        )}
                    </div>
                </div>
                <div
                    className={`bp3-card bp3-elevation-0 large ${
                        !this.isAssessmentDashboardPanelActive(
                            prosureAssessment
                        ) && 'not-active'
                    }`}
                >
                    <div className="logo">
                        <img
                            className={`prosure-assessment-logo`}
                            src={'/assets/images/badges/vantify-ssip.svg'}
                            alt={'Vantify SSIP logo'}
                        />
                    </div>
                    <div className={'content'}>
                        <h2>Vantify Verified</h2>
                        {this.isAssessmentDashboardPanelActive(
                            prosureAssessment
                        ) ? (
                            <p>
                                Last Completed Date:{' '}
                                {moment(prosureAssessment.completedAt).format(
                                    'Do MMMM YYYY'
                                )}
                            </p>
                        ) : (
                            <div className={'moreDetails'}>Purchase Here</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    loadAll() {
        this.loadCompany();
        this.loadRecentAssessments();
        this.loadSupplyChain();
    }

    loadRecentAssessments() {
        if (this.state.isLoadingProsureAssessments) {
            return;
        }

        this.setState({ isLoadingProsureAssessments: true });
        this.getAssessmentType(
            'isProsurePreQualified',
            'prosurePreQualifiedAssessment'
        );
        this.getAssessmentType('isSSIPAccreditation', 'prosureAssessment');
    }

    getAssessmentType(flag, type) {
        HTTP.get('/assessment-type-instances', {
            [flag]: 1,
            limit: 1,
            isVerified: 1,
            status: 'completed',
            order: '-completed',
        }).then((response) => {
            if (response) {
                if (response.data.count > 0) {
                    this.setState({
                        [type]: response.data.assessmentTypeInstances[0],
                    });
                }

                return true;
            }

            toast.error('Unable to fetch Assessment Type');

            return false;
        });
    }

    loadCompany() {
        if (this.state.isLoadingCompany) {
            return;
        }

        if (Response.getLink(this.props.rootResponse, 'company') === null) {
            window.setTimeout(() => {
                this.loadCompany();
            }, 250);

            return;
        }

        this.setState({ isLoadingCompany: true });
        const { rootResponse } = this.props;
        window.clearInterval(this.companyInterval);

        HTTP.get(Response.getLink(rootResponse, 'company')).then((response) => {
            if (response) {
                this.setState(
                    { isLoadingCompany: false, companyResponse: response.data },
                    () => {
                        this.companyInterval = window.setInterval(() => {
                            this.loadCompany();
                        }, 3600000);
                        this.loadState();
                        this.loadDashboardStats();
                    }
                );

                return true;
            }

            toast.error('Unable to fetch Company');
            this.setState({ isLoadingCompany: false });

            return false;
        });
    }

    loadState() {
        if (this.state.isLoadingState) {
            return;
        }

        this.setState({ isLoadingState: true });
        const { companyResponse } = this.state;

        HTTP.get(Response.getLink(companyResponse, 'state')).then(
            (response) => {
                if (response) {
                    this.setState(
                        {
                            isLoadingState: false,
                            state: response.data,
                        } /*, () => {
                        this.stateInterval = window.setInterval(() => {
                            this.loadState();
                        }, 3000);
                    }*/
                    );

                    return true;
                }

                toast.error('Unable to fetch State');
                this.setState({ isLoadingState: false });

                return false;
            }
        );
    }

    loadDashboardStats = async () => {
        if (this.state.isLoadingDashboardStats) {
            return;
        }

        const { companyResponse } = this.state;

        this.setState({ isLoadingDashboardStats: true });

        const dashboardLink = Response.getLink(companyResponse, 'dashboard');

        if (dashboardLink) {
            try {
                const dashboardStats = await CompanyService.loadDashboardStats(
                    dashboardLink
                );

                this.setState({
                    isLoadingDashboardStats: false,
                    assessmentsCnt: dashboardStats.assessmentsCnt,
                    insurancesCnt: dashboardStats.insurancesCnt,
                    certificationsCnt: dashboardStats.certificationsCnt,
                    taskCnt: dashboardStats.taskCnt,
                });
            } catch (e) {
                toast.error('Failed to load dashboard stats.');
                console.warn(e.code, e.message);

                return false;
            }
        }
    };

    loadSupplyChain = () => {
        if (this.state.isLoadingSupplyChain) {
            return;
        }

        this.setState({ isLoadingSupplyChain: true });

        HTTP.get('/supply-chain', { status: 'status_active' }).then(
            (response) => {
                if (response) {
                    this.setState({
                        isLoadingSupplyChain: false,
                        activeSupplyChainsCnt: response.data.count,
                    });

                    return true;
                }

                toast.error('Unable to fetch Supply Chain data');
                this.setState({ isLoadingSupplyChain: false });

                return false;
            }
        );

        HTTP.get('/supply-chain', { status: 'status_pending' }).then(
            (response) => {
                if (response) {
                    this.setState({
                        isLoadingSupplyChain: false,
                        pendingInvitationsCnt: response.data.count,
                    });

                    return true;
                }

                toast.error('Unable to fetch Supply Chain data');
                this.setState({ isLoadingSupplyChain: false });

                return false;
            }
        );
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard);
