import { SUPPORT_EMAIL } from 'constants/general';
import './index.scss';

const ContactUs = () => (
    <div className="page ContactUsPage">
        <div className="page-wrapper no-styling">
            <div className="page-header">
                <h1 className="page-title">Contact Us</h1>
            </div>
            <div className="page-body">
                <p className="text-center">
                    Our team is here to try and help you with any queries you might have.
                </p>
                <p className="text-center py-5">
                    <div>
                        <b>Telephone: </b> <a href="tel:02034046750">0203 404 6750</a>
                    </div>
                    <div>
                        <b>Email: </b> <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                    </div>
                </p>
                <p className="text-center">
                    If you have a complaint about our service, please contact us on: <br/>
                    <a href="mailto:complaints.supplychain@vantify.com">complaints.supplychain@vantify.com</a>
                </p>
            </div>
        </div>
    </div>
);

export default ContactUs;