import { Button, H1 } from '@blueprintjs/core';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import ButtonLink from 'components/elements/ButtonLink';
import Icon from 'components/elements/Icon';
import Loading from 'components/elements/wrappers/Loading';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HTTP from 'service/HTTP';
import Response from 'service/Response';
import { ROLE_OPTIONS } from '../../../constants/role';
import { USER_STATUS_TRANSLATION } from '../../../constants/user';
import { translate } from '../../../service/Translator';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class UserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            response: {},
        };

        this.load = this.load.bind(this);
        this.unlockUser = this.unlockUser.bind(this);
        this.getCompanyClientName = this.getCompanyClientName.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    getCompanyClientName = () => {
        const companyName = Response.getLinkAttribute(
            this.state.response,
            'company',
            'registeredCompanyName'
        );
        const companyId = Response.getLinkAttribute(
            this.state.response,
            'company',
            'id'
        );

        if (!!companyId) {
            return (
                <Link to={'/search/' + companyId}>
                    {companyName}
                </Link>
            );
        }

        const clientName = Response.getLinkAttribute(
            this.state.response,
            'client',
            'name'
        );
        const clientId = Response.getLinkAttribute(
            this.state.response,
            'client',
            'id'
        );

        if (!!clientId) {
            return <Link to={'/clients/list/' + clientId}>{clientName}</Link>;
        }

        return <></>;
    };

    render() {
        const { isLoading, response } = this.state;
        const { match } = this.props;

        return (
            <div className="UserDetails">
                <Loading isLoading={isLoading}>
                    <H1 className="clearfix">
                        {response.forename} {response.surname}
                        <div className="float-right">
                            {!response.isLocked ? null : (
                                <Button
                                    type="button"
                                    onClick={this.unlockUser}
                                    intent="primary"
                                    className="me-3"
                                >
                                    <Icon icon="lock-open" />
                                    Unlock
                                </Button>
                            )}

                            {Response.getLink(response, 'edit') ===
                            null ? null : (
                                <ButtonLink
                                    type="button"
                                    to={`${match.url}/edit`}
                                    intent="primary"
                                >
                                    <Icon icon="pencil" />
                                    Edit
                                </ButtonLink>
                            )}
                        </div>
                    </H1>
                    <dl className="grid small">
                        <dt>Forename</dt>
                        <dd>{response.forename}</dd>

                        <dt>Surname</dt>
                        <dd>{response.surname}</dd>

                        <dt>Email</dt>
                        <dd>{response.email}</dd>

                        <dt>Job Title</dt>
                        <dd>{response.jobTitle}</dd>

                        <dt>Phone</dt>
                        <dd>{response.phone || 'not provided'}</dd>

                        <dt>Status</dt>
                        <dd>
                            {translate(
                                response.isActive,
                                USER_STATUS_TRANSLATION
                            )}
                        </dd>

                        <dt>Roles</dt>
                        {!response.roles
                            ? null
                            : response.roles.map((role, key) => {
                                  return <dd key={'role' + key}>{role}</dd>;
                              })}

                        <dt>Service Provider / Client</dt>
                        <dd>{this.getCompanyClientName()}</dd>
                    </dl>
                </Loading>
            </div>
        );
    }

    load() {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        HTTP.get(`/users/${this.state.id}`, { datagroup: 'details' }).then(
            (response) => {
                if (response) {
                    response.data.roles = response.data.roles
                        .filter((role) => role !== 'ROLE_USER')
                        .map(
                            (role) =>
                                ROLE_OPTIONS.find(
                                    (option) => option.value === role
                                ).label
                        );

                    this.setState({
                        isLoading: false,
                        response: response.data,
                    });
                    this.props.updatingBreadcrumbResolves({
                        user: {
                            forename: response.data.forename,
                            surname: response.data.surname,
                        },
                    });

                    return true;
                }

                toast.error('Unable to fetch User details');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }

    unlockUser() {
        return HTTP.put(`/users/${this.state.id}/unlock`, {}).then(
            (response) => {
                if (response) {
                    toast.success('User unlocked successfully');

                    this.load();
                    return true;
                }

                toast.error('Unable to unlock user');

                return false;
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
