import { Tab, Tabs } from '@blueprintjs/core';
import { Link, Redirect, Switch } from 'react-router-dom';

import { Icon, ProtectedRoute } from 'components/elements';
import IntegrationAdd from 'components/user/Integrations/IntegrationAdd';
import IntegrationsList from 'components/user/Integrations/IntegrationsList';
import { Routing } from 'service';

const IntegrationsTabs = (props: any) => {
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="integrations">
            <div>
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/vantify-cafm'}>
                        <Link to={`${match.url}/vantify-cafm`}>
                            <Icon icon="building" />
                            Vantify CAFM
                        </Link>
                    </Tab>
                </Tabs>

                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/vantify-cafm`}
                            component={IntegrationsList}
                            integrationType="Vantify CAFM"
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/vantify-cafm/add`}
                            component={IntegrationAdd}
                            integrationType="Vanitfy CAFM"
                            exact
                        />
                        <Redirect to={`${match.url}/vantify-cafm`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default IntegrationsTabs;
