import { AxiosResponse } from 'axios';

import {
    emptyListData,
    TaskData,
    TaskListData,
    TaskListFilter,
} from 'interface';
import { HTTP } from 'service';

export const loadTaskList = async (
    filter?: Partial<TaskListFilter>
): Promise<TaskListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        '/tasks',
        filter ?? {},
        {},
        'Unable to load Task list'
    );

    if (response && response.data) {
        return {
            ...response.data,
            data: response.data.tasks,
        } as TaskListData;
    } else {
        return {
            ...emptyListData,
            tasks: [],
            _links: [],
            data: [],
        } as TaskListData;
    }
};

export const getRouteFromType = (task: TaskData) => {
    let route = '';

    switch (task.type) {
        case 0:
            route = '/shop/';
            break;
        case 3:
            route = '/assessments';
            break;
        case 1:
        case 5:
        case 6:
            route = '/company/insurances';
            break;
        case 7:
            route = '/integrations/elogbooks/add';
            break;
        case 2:
        case 4:
        default:
            route = `/supply-chains/pending/${task.supplyChainId}`;
            break;
    }

    // client assessments are intended to go to the supply chain purchase page
    // unless already joined then we can assume it exists already
    if (
        task.isClientAssessment === true &&
        task.hasJoinedSupplyChain !== true
    ) {
        route = `/supply-chains/pending/${task.supplyChainId}`;
    }

    return route;
};
