import { InputGroup } from '@blueprintjs/core';
import React, { ComponentProps, useEffect, useReducer, useRef } from 'react';

import { InputElementIcon, TooltipIcon } from 'components/elements';
import { USER_PASSWORD_LENGTH_MINIMUM } from 'constants/user';

interface PasswordProps extends ComponentProps<any> {
    showPassword: boolean;
    showInfo: boolean;
    showError: boolean;
    marloweTheme: boolean;
    errorMessage: string;
    inputRef: React.MutableRefObject<any>;
}

const PasswordField = (props: Partial<PasswordProps>) => {
    const {
        showInfo,
        showError,
        showPassword,
        errorMessage,
        onShowPasswordChange,
        inputRef,
        marloweTheme,
        ...rest
    } = props;
    const [state, setState] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        {
            showThePassword: showPassword || false,
            errorMessage: undefined,
        }
    );

    const { showThePassword } = state;
    const ref = useRef();
    /** Password pattern should match 1 Uppercase, 1 letter and a [symbol: not A-Z, a-z, 0-9 or space] */
    const pattern = `^(?=.*[0-9])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\\t\\n\\s])(.{${USER_PASSWORD_LENGTH_MINIMUM},75})`;
    const patternMsg = `Password must have at least 1 uppercase letter, 1 number and 1 symbol, and be a minimum of ${USER_PASSWORD_LENGTH_MINIMUM} characters`;

    useEffect(() => {
        const loop = setInterval(checkError, 100);

        return () => {
            clearInterval(loop);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        typeof onShowPasswordChange === 'function' &&
            onShowPasswordChange(showThePassword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showThePassword]);

    const checkError = () => {
        const target = (inputRef || ref).current as HTMLInputElement;

        if (target.validity && target.validity.valid) {
            setState({ errorMessage: undefined });
        } else {
            setState({ errorMessage: target.validationMessage });
        }
    };

    return (
        <div className="input-with-error">
            <div className="input-with-tooltip">
                <InputGroup
                    inputRef={inputRef || ref}
                    type={showThePassword ? 'text' : 'password'}
                    placeholder="Password"
                    pattern={pattern}
                    rightElement={InputElementIcon(
                        ...(showThePassword
                            ? ['Hide Password', 'eye-slash']
                            : ['Show Password', 'eye']),
                        () => setState({ showThePassword: !showThePassword })
                    )}
                    title={patternMsg}
                    required
                    {...rest}
                />
                {!showInfo ? null : !marloweTheme ? (
                    <TooltipIcon text={props.title || patternMsg} icon="info" />
                ) : (
                    <div className="ms-1">
                        <TooltipIcon
                            text={props.title || patternMsg}
                            icon="info-circle"
                        />
                    </div>
                )}
            </div>
            {showError && (
                <div className="error-message">
                    {errorMessage || state.errorMessage}
                </div>
            )}
        </div>
    );
};

export default PasswordField;
