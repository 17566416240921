import { useEffect, useState } from 'react';

import { ButtonLink, Icon, List } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { parseDateFormat, toMoney } from 'helpers/helpers';
import { ListCollection } from 'interface';
import {
    Campaign,
    CampaignListFilter,
    CampaignSearchListFilter,
} from 'interface/Client/Campaign';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { ClientService, Response } from 'service';
import CampaignService from 'service/Client/CampaignService';

interface CampaignListProps extends RouteComponentProps<any> {}

const CampaignList = (props: CampaignListProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<ListCollection<Campaign>>(
        {} as ListCollection<Campaign>
    );
    const defaultFilters = {
        order: '-startDate',
        datagroup: 'list',
    };
    const [listFilter, setListFilter] = useState<CampaignListFilter>({
        ...defaultFilters,
    } as CampaignListFilter);
    const [searchFilters, setSearchFilters] =
        useState<CampaignSearchListFilter>({
            client: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Client',
                key: 'client',
                options: [],
            },
        });

    const viewButtons = (row: Campaign) => {
        return (
            <ButtonLink
                type="button"
                to={`/clients/campaigns/list/${row.id}`}
                intent="primary"
            >
                <Icon icon="eye" />
                View
            </ButtonLink>
        );
    };

    const columns: object[] = [
        {
            name: 'Client',
            property: 'clientName',
            type: 'callback',
            callback: (row: Campaign) => {
                return Response.getLinkAttribute(row, 'client', 'name');
            },
            sortable: true,
            grow: 3,
        },
        { name: 'Campaign Name', property: 'name', sortable: true, grow: 3 },
        {
            name: 'Campaign Start Date',
            property: 'startDate',
            type: 'callback',
            sortable: true,
            callback: (row: Campaign) => {
                return <>{parseDateFormat(row.startDate, 'DD/MM/YYYY')}</>;
            },
            grow: 2,
        },
        {
            name: 'Invitees',
            property: 'numberOfInvitees',
            type: 'string',
            sortable: true,
            grow: 1,
        },
        {
            name: 'Declined',
            property: 'numberOfInviteesDeclined',
            type: 'callback',
            sortable: true,
            grow: 1,
            callback: (row: Campaign) => {
                return (
                    <>
                        <span className="me-1">
                            {row.numberOfInviteesDeclined || 0}
                        </span>
                        <span>
                            (
                            {Math.floor(
                                (parseInt(row.numberOfInviteesDeclined) /
                                    parseInt(row.numberOfInvitees)) *
                                    100
                            ) || 0}
                            %)
                        </span>
                    </>
                );
            },
        },
        {
            name: 'Joined Vantify Supply Chain',
            property: 'joinedProsure',
            type: 'callback',
            sortable: true,
            grow: 1,
            callback: (row: Campaign) => {
                return (
                    <>
                        <span className="me-1">
                            {row.numberOfInviteesJoined || 0}
                        </span>
                        <span>
                            (
                            {Math.floor(
                                (parseInt(row.numberOfInviteesJoined) /
                                    parseInt(row.numberOfInvitees)) *
                                    100
                            ) || 0}
                            %)
                        </span>
                    </>
                );
            },
        },
        {
            name: 'Joined Supply Chain',
            property: 'joinedSupplyChain',
            type: 'callback',
            grow: 2,
            callback: (row: Campaign) => {
                return (
                    <>
                        <span className="me-1">
                            {row.numberOfJoinedSupplyChains || 0}
                        </span>
                        <span>
                            (
                            {Math.floor(
                                (parseInt(row.numberOfJoinedSupplyChains) /
                                    parseInt(row.numberOfInvitees)) *
                                    100
                            ) || 0}
                            %)
                        </span>
                    </>
                );
            },
        },
        {
            name: 'Assessment Revenue',
            property: 'totalAssessmentRevenueExVat',
            type: 'callback',
            callback: (row: Campaign) => {
                return toMoney(row.totalAssessmentRevenueExVat);
            },
            sortable: true,
            grow: 2,
        },
        {
            name: 'Supply Chain Fee - Vantify',
            property: 'totalSupplyChainRevenueExVat',
            type: 'callback',
            callback: (row: Campaign) => {
                return toMoney(row.totalSupplyChainRevenueExVat);
            },
            sortable: true,
            grow: 2,
        },
        {
            name: 'Revenue per Invitee',
            property: 'revenuePerInvitee',
            type: 'callback',
            callback: (row: Campaign) => {
                const revenueExVat = parseFloat(
                    row.totalAssessmentRevenueExVat
                );
                const totalSupplyChainRevenueExVat = parseFloat(
                    row.totalSupplyChainRevenueExVat
                );
                const numInvitees = parseInt(row.numberOfInvitees);

                return toMoney(
                    (revenueExVat + totalSupplyChainRevenueExVat) / numInvitees
                );
            },
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            property: '',
            type: 'callback',
            grow: 3,
            callback: viewButtons,
            right: true,
        },
    ];

    const load = async (filter: CampaignListFilter) => {
        setCollection(await CampaignService.loadList(filter));
        setListFilter(filter);
    };

    const downloadHandler = async (filter: CampaignListFilter) => {
        await CampaignService.downloadCampaigns(filter);

        toast.success('Campaign List Downloaded');
    };

    useEffect(() => {
        (async () => {
            if (isLoading) {
                const copySearchFilters = { ...searchFilters };

                copySearchFilters.client.options =
                    await ClientService.getClientListForDropdown();
                setSearchFilters(copySearchFilters);

                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const getHeaderButton = () => {
        return (
            <ButtonLink
                type="button"
                to={`/clients/campaigns/new`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add Campaign
            </ButtonLink>
        );
    };

    return (
        <div className="CampaignList">
            <List
                title="Campaign Management"
                columns={columns}
                load={(filter) => load(filter as CampaignListFilter)}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading}
                noRecordsFoundText="There are no campaigns"
                headerButton={getHeaderButton}
                filters={searchFilters}
                downloadHandler={downloadHandler}
            />
        </div>
    );
};

export default withRouter(CampaignList);
