import { H1 } from '@blueprintjs/core';
import { ButtonLink } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';
import { checkUserCredentials } from 'service/Integration/IntegrationService';

import './index.scss';

interface ClientIntegrationProps {
    clientResponse: { id: string };
    match: any;
}

interface ClientIntegrationData {
    clientId: string;
    hasElogs: boolean;
    hasMeridian: boolean;
    elogsClientName: string;
    elogsUrl: string;
    elogsApiUrl: string;
}

type CafmConnectionStatus = {
    classStatus: string;
    statusValue: string;
};

const ClientIntegrations = (props: ClientIntegrationProps) => {
    const [loading, setLoading] = useState(true);
    const [cafmConnectionStatus, setCafmConnectionStatus] =
        useState<CafmConnectionStatus>({
            classStatus: 'cafm-check-pending',
            statusValue: 'Checking....',
        });

    const [data, setData] = useState<ClientIntegrationData | null>(null);

    const load = () => {
        const { clientResponse } = props;

        setLoading(true);

        (async () => {
            await HTTP.get(`/clients/${clientResponse?.id}/integration`).then(
                (response) => {
                    if (response) {
                        setLoading(false);
                        setData(response.data);

                        return true;
                    }

                    toast.error('Unable to fetch integration details');
                    setLoading(false);

                    return false;
                }
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        const { clientResponse } = props;

        if (data?.hasElogs) {
            checkUserCredentials(clientResponse.id).then(
                (cafmUserCredentialsCheck) => {
                    let classStatusValue =
                        cafmUserCredentialsCheck.indexOf('401') !== -1 ||
                        cafmUserCredentialsCheck.indexOf('404') !== -1 ||
                        cafmUserCredentialsCheck.indexOf('0 Site') !== -1 ||
                        cafmUserCredentialsCheck.indexOf('0 Service Provi') !==
                            -1
                            ? 'cafm-check-fail'
                            : 'cafm-check-good';
                    setCafmConnectionStatus({
                        classStatus: classStatusValue,
                        statusValue: cafmUserCredentialsCheck,
                    });
                }
            );
        }
    }, [data]);

    const getStringFromBool = (value: any) => (value ? 'Yes' : 'No');

    const renderElogsUrl = () => {
        if (!data?.hasElogs) {
            return null;
        }

        return (
            <>
                <dt>Vantify CAFM Client Name</dt>
                <dd>{data?.elogsClientName || '-'}</dd>

                <dt>Vantify CAFM URL</dt>
                <dd>{data?.elogsUrl || '-'}</dd>

                <dt>Vantify CAFM API URL</dt>
                <dd>{data?.elogsApiUrl || '-'}</dd>

                <dt>Vantify CAFM status</dt>
                <dd>
                    <span className={cafmConnectionStatus.classStatus}>
                        {cafmConnectionStatus.statusValue}
                    </span>
                </dd>
            </>
        );
    };

    const renderMeridianCompanyId = () => {
        if (!data?.hasMeridian) {
            return null;
        }

        return (
            <>
                <dt>Company Reference (Vantify Risk Manager)</dt>
                <dd>{data?.clientId}</dd>
            </>
        );
    };

    return (
        <div className="ClientInfo">
            <H1 className="clearfix">
                Integrations
                {Response.getLink(props?.clientResponse, 'edit') ===
                null ? null : (
                    <ButtonLink
                        type="button"
                        to={`${props.match.url}/edit`}
                        intent="primary"
                        className="float-right"
                    >
                        Edit
                    </ButtonLink>
                )}
            </H1>
            <Loading isLoading={loading}>
                <dl className="horizontal">
                    <dt>Vantify CAFM Integration</dt>
                    <dd>{getStringFromBool(data?.hasElogs)}</dd>

                    {renderElogsUrl()}

                    <dt>Vantify Risk Manager Integration</dt>
                    <dd>{getStringFromBool(data?.hasMeridian)}</dd>

                    {renderMeridianCompanyId()}
                </dl>
            </Loading>
        </div>
    );
};

export default ClientIntegrations;
