import moment from 'moment';
import { useState } from 'react';

import { List } from 'components/elements';
import { ASSESSMENT_TYPE_MILESTONES } from 'constants/assessmentTypeMilestones';
import { delay, parseDateFormat, toMoney } from 'helpers/helpers';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    ListCollection,
} from 'interface';
import { CampaignComponentProps } from 'interface/Client/Campaign';
import { Link } from 'react-router-dom';
import { InstanceService, Response } from 'service';

const CampaignPurchaseTracker = (props: CampaignComponentProps) => {
    const { campaign } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const defaultFilters = {
        page: 1,
        limit: 10,
        order: '-purchasedAt',
        campaignId: campaign.id,
    };

    const [listFilter, setListFilter] =
        useState<AssessmentTypeInstanceListFilter>({
            ...defaultFilters,
        } as AssessmentTypeInstanceListFilter);

    const [collection, setCollection] = useState<
        ListCollection<AssessmentTypeInstanceData>
    >({
        count: 0,
    } as ListCollection<AssessmentTypeInstanceData>);

    const load = async (searchFilter: AssessmentTypeInstanceListFilter) => {
        const { collection, filter } = await InstanceService.loadList(
            props,
            { ...searchFilter, campaignId: campaign.id }
        );

        setCollection(collection);

        setListFilter({ ...defaultFilters, ...filter });
        await delay(0);
        setIsLoading(false);
    };

    const columns: object[] = [
        {
            name: 'Service Provider Name',
            type: 'callback',
            grow: 3,
            callback: (row: AssessmentTypeInstanceData) => {
                return row._links.company ? (
                    <Link
                        to={
                            '/search/' +
                            Response.getLinkAttribute(row, 'company', 'id')
                        }
                    >
                        {row._links.company.registeredCompanyName}
                    </Link>
                ) : (
                    'TBC'
                );
            },
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Purchased Date',
            type: 'datetime',
            property: 'purchasedAt',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Completed Date',
            type: 'datetime',
            property: 'completedAt',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Assessment Fee (Ex Vat)',
            property: '_links.events.paymentAmount',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                return toMoney(row._links.events.paymentAmount);
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Current Status',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                return 'Submitted - ' + ASSESSMENT_TYPE_MILESTONES[row.status];
            },
            sortable: false,
            grow: 2,
        },
    ];

    const startDate = parseDateFormat(campaign.startDate, 'DD/MM/YYYY');
    const endDate = moment(campaign.startDate).add(1, 'y').format('DD/MM/YYYY');
    const description = `Campaign Started ${startDate}, with purchase tracking expiring ${endDate}`;

    return (
        <div className="CampaignInviteeList">
            <List
                title="Purchase Tracker"
                columns={columns}
                load={(filter) =>
                    load(filter as AssessmentTypeInstanceListFilter)
                }
                preLoadList={false}
                delayedLoad={!!campaign?.id}
                hideFilters={true}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading || !campaign?.id}
                noRecordsFoundText="There are no campaign invitees"
                description={description}
            />
        </div>
    );
};

export default CampaignPurchaseTracker;
