import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import {
    ComponentProps,
    SyntheticEvent,
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import { toast } from 'react-toastify';

import { MultiSelectRenderer } from 'components/elements';
import { ClientData, Company } from 'interface';
import { Response } from 'service';
import {
    handleSubmitIntegration,
    loadClientELBServiceProviders,
} from 'service/Integration/IntegrationService';

interface LinkElogsServiceProviderProps extends ComponentProps<any> {
    company: Company;
    client: ClientData;
    isOpen: boolean;
    onClose: Function;
    onSubmit?: Function;
}

const LinkElogsServiceProvider = (props: LinkElogsServiceProviderProps) => {
    const { client, company, isOpen, onClose, onSubmit } = props;

    const defaultState = {
        dialogOpen: !!isOpen,
        isLoading: false,
        errorMessage: null,
        elogsSps: {
            count: 0,
            serviceProviders: [],
        },
        SPsToIntegrate: [],
    };

    const [state, setState] = useReducer((state: any, edits: any) => {
        return { ...state, ...edits };
    }, defaultState);

    const load = useCallback(
        async (filters: any = {}) => {
            const defaultFilters = {
                page: 1,
                limit: 999,
                name: '',
            };
            const elogsSps = await loadClientELBServiceProviders(client.id, {
                ...defaultFilters,
                ...filters,
            });
            setState({
                elogsSps,
                errorMessage: !elogsSps.count
                    ? 'No ELB Service Providers found'
                    : null,
            });
        },
        [client?.id]
    );

    useEffect(() => {
        setState({ dialogOpen: !!isOpen });
        if (!!isOpen) {
            load();
        }
    }, [isOpen, load]);

    const dialogClose = (event: SyntheticEvent) => {
        setState(defaultState);
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!company || !client || !state.elogsSps.count) {
            setState({
                errorMessage: 'Submission not possible. Please contact support',
            });
            toast.error('Service Provider cannot be linked');
            return;
        }
        if (!state.SPsToIntegrate.length) {
            setState({
                errorMessage:
                    'Please pick an Vantify CAFM Service Provider from the dropdown',
            });
            toast.error('Could not submit. Please complete all fields.');
            return;
        }

        setState({ isLoading: true });
        const response = await handleSubmitIntegration({
            clientId: client.id,
            companyId: company.id,
            serviceProviderAdd: state.SPsToIntegrate,
            _links: {
                company: {
                    href: Response.getLink(company, 'self'),
                    method: 'GET',
                },
            },
        });

        if (!response) {
            setState({ isLoading: false });
            return;
        }

        typeof onSubmit === 'function' && onSubmit(response);
        setState({ isLoading: false });
        dialogClose({} as SyntheticEvent);
    };

    return (
        <Dialog
            onClose={dialogClose}
            title="Link Service Provider to Vantify CAFM"
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="mb-2">
                    Please link with one of your Vantify CAFM Service Providers.
                </div>

                <FormGroup
                    label={'Vantify CAFM Service Provider(s)'}
                    labelFor={'elbServiceProviders'}
                    className={'form-fill multi-select-container'}
                >
                    <MultiSelectRenderer
                        id={'elbServiceProviders'}
                        onRemoveItem={(items: any) =>
                            setState({ SPsToIntegrate: items })
                        }
                        onItemSelect={(items: any) =>
                            setState({ SPsToIntegrate: items })
                        }
                        file={true}
                        items={state.elogsSps?.serviceProviders}
                        selectedItems={state.SPsToIntegrate}
                        valueProperty={'elogbooksName'}
                        placeholder={'Select Service Provider(s)'}
                        onKeyUp={(e: any) => {
                            load({ name: e.target.value });
                        }}
                    />
                </FormGroup>

                {state.errorMessage && (
                    <span className="error">{state.errorMessage}</span>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={dialogClose}>Cancel</Button>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            type="submit"
                            intent={Intent.SUCCESS}
                            disabled={state.isLoading}
                            onClick={dialogConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default LinkElogsServiceProvider;
