import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import List from 'components/elements/List';
import {
    COMMENT_POLARITY_MAPPING,
    COMMENT_POLARITY_OPTIONS,
    COMMENT_TYPE_MAPPING,
    COMMENT_TYPE_OPTIONS,
} from 'constants/comments';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeInstanceCommentData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, UserService } from 'service';

import './index.scss';

interface AssessmentCommentsListFilters {
    rootAssessment: ListSearchFilter;
    lastAssessedBy: ListSearchFilter;
    type: ListSearchFilter;
    polarity: ListSearchFilter;
}

const AssessmentTypeInstanceCommentsList = (
    props: AssessmentTypeInstanceListProps
) => {
    const { load, collection, filter, isLoading, defaultFilter } = props;
    const [searchFilters, setSearchFilters] =
        useState<AssessmentCommentsListFilters>({
            rootAssessment: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessment',
                key: 'rootAssessment',
                options: [],
            },
            lastAssessedBy: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessor',
                key: 'lastAssessedBy',
                options: [],
            },
            type: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Comment Type',
                key: 'type',
                options: [NONE_SELECTED_OPTION, ...COMMENT_TYPE_OPTIONS],
            },
            polarity: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Polarity',
                key: 'polarity',
                options: [NONE_SELECTED_OPTION, ...COMMENT_POLARITY_OPTIONS],
            },
        });

    const companyRoute = (row: AssessmentTypeInstanceCommentData) => {
        return `/search/${row.companyId}`;
    };

    const companyName = (row: AssessmentTypeInstanceCommentData) => {
        return row.registeredCompanyName;
    };

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: '-completedAt',
        status: 'completed-comments',
    };

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Assessment',
            property: 'assessmentTypeName',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Service Provider',
            property: 'registeredCompanyName',
            type: 'nav-button',
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
            sortable: false,
            grow: 4,
        },
        {
            name: 'Assessor',
            property: 'lastAssessedBy',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Reviewer',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceCommentData) => {
                const value = get(row, 'lastReviewedBy');
                return value ? value : 'None';
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Completed Date',
            type: 'datetime',
            property: 'completedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Comment Type',
            type: 'mapping',
            mapping: COMMENT_TYPE_MAPPING,
            property: 'type',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Polarity',
            type: 'mapping',
            mapping: COMMENT_POLARITY_MAPPING,
            property: 'polarity',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Comment',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceCommentData) => {
                return (
                    <span
                        className={`comment-ellipsis`}
                        onClick={(
                            event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                        ) => {
                            const className = 'comment-ellipsis';
                            const target = event.target as HTMLInputElement;
                            if (target.className.includes('show')) {
                                target.className = className;
                            } else {
                                target.className = className + ' show';
                            }
                        }}
                    >
                        {get(row, 'comment')}
                    </span>
                );
            },
            property: 'comment',
            sortable: false,
            grow: 2,
        },
    ];

    useEffect(() => {
        (async () => {
            const copySearchFilters: AssessmentCommentsListFilters = {
                ...searchFilters,
            };

            copySearchFilters.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            copySearchFilters.lastAssessedBy.options =
                await UserService.getAssessorDropdown();

            setSearchFilters(copySearchFilters);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page ServiceProviderDetails">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Assessment Comments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        isLoading={isLoading}
                        filters={searchFilters}
                        defaultFilter={defaultSpecificFilter}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstanceCommentsList;
