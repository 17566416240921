import { useEffect, useState } from 'react';

import {
    ButtonLink,
    Icon,
    ListHeaderControls,
    LoadingIndicator,
    Paginator,
    Table
} from 'components/elements';
import SingleSelect from 'components/elements/filter/SingleSelect';
import Text from 'components/elements/filter/Text';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    ELBClientListData,
    IntegrationData,
    IntegrationsComponentProps,
    IntegrationsFilter,
    IntegrationsListData
} from 'interface/Integrations';
import {
    deleteIntegration,
    loadELBClients,
    loadIntegrationList
} from 'service/Integration/IntegrationService';

import { Location } from 'service';
import './integrations.scss';

interface ClientOption {
    label: string;
    value: any;
};

const IntegrationsList = (props: Partial<IntegrationsComponentProps>) => {
    const { history, location } = props;

    const [collectionResponse, setCollectionResponse] =
        useState<IntegrationsListData>();

    const [collectionTotal, setCollectionTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const defaultFilters: IntegrationsFilter = {
        order: '-createdAt',
        status: 'status_live',
        type: 'elogbooks',
    };

    const [filters, setFilters] = useState<{ [str: string]: string }>({
        order: defaultFilters.order,
    });

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [clients, setClients] = useState<ClientOption[]>([]);
    const [clientLoading, setClientLoading] = useState(false);

    const handleRemoveIntegration = (event: any, integration: IntegrationData) => {
        deleteIntegration(integration.id)
            .then(() => {
                load();
            }
        );
    };

    const columns: object[] = [
        {
            name: `Vantify Supply Chain Service Provider`,
            property: '_links.company.name',
            sortable: false,
            grow: 3,
        },
        {
            name: `Vantify CAFM Client`,
            property: 'clientName',
            sortable: false,
            grow: 2,
        },
        {
            name: `Vantify CAFM Service Provider`,
            property: 'companyReferenceName',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Link Created By',
            property: 'createdby',
            type: 'string',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Link Created Date',
            property: 'createdAt',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: '',
            property: '',
            type: 'button',
            onClick: handleRemoveIntegration,
            grow: 1,
            intent: 'primary',
            text: 'Remove',
            right: true,
        }
    ];

    const load = (params = {}, paramsOverride = false) => {
        (async () => {
            setIsLoading(true);

            let filterParams = Object.assign(
                {},
                defaultFilters,
                props.filter,
                filters,
                params
            );

            if (paramsOverride) {
                filterParams = params;
            }

            Location.setQueryStringFromObject(history, location, filterParams);
            setFilters(filterParams);

            const integrationList: IntegrationsListData = await loadIntegrationList(filterParams);
            setCollectionTotal(integrationList.count);
            setCollectionResponse(integrationList);

            setIsLoading(false);
        })();
    };

    const loadClients = () => {
        (async () => {
            setClientLoading(true);

            const clients: ELBClientListData = await loadELBClients(
               { order: 'client' }
            );

            let clientOptions: ClientOption[] = [];

            if (clients.serviceProviders.length) {
                clientOptions = clients.serviceProviders.map((client) => (
                    {
                        label: client.elogbooksClient,
                        value: client.elogbooksClient
                    }
                ));
            }

            setClients([NONE_SELECTED_OPTION, ...clientOptions]);

            setClientLoading(false);
        })();
    };

    const toggleFilter = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const onFilter = (filters: any, filterData: any) => {
        delete filters.modifiers;

        let newFilters = Object.assign({}, defaultFilters, filters, { page: 1 });
        const { serviceProviderName } = newFilters;

        if (typeof serviceProviderName === "string") {
            newFilters.serviceProviderName = serviceProviderName.toLowerCase();
        }

        load(newFilters, true);
    };

    const clearFilters = () => {
        setFilters({ ...defaultFilters });
        onFilter({}, {});
    };

    useEffect(() => {
        load();
        loadClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (isLoading || clientLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="IntegrationsList">
            <div className='integrations-header'>
                <ListHeaderControls
                    title='Integrations List'
                    count={collectionTotal}
                    onReload={load}
                    onFilter={toggleFilter}
                />

                <ButtonLink
                    type="button"
                    to={`${props.match.url}/add`}
                    intent="primary"
                >
                    <Icon icon="plus-circle" />
                    Add Vantify CAFM Integrations
                </ButtonLink>
            </div>

            <Filter
                onClear={clearFilters}
                filterData={filters}
                isOpen={isFilterOpen}
                onFilter={onFilter}
            >
                <Text label="Service Provider" filterKey="serviceProviderName" />
                <SingleSelect
                    label="Client"
                    filterKey="clientName"
                    options={clients}
                    defaultValue={filters.clientName}
                />
            </Filter>
            <Loading isLoading={isLoading}>
                <NoResultsFound count={collectionTotal}>
                    <Table
                        data={collectionResponse?.integrations}
                        columns={columns}
                        onSort={load}
                        ordering={defaultFilters.order}
                    />

                    <Paginator
                        page={collectionResponse?.page}
                        count={collectionResponse?.count}
                        limit={collectionResponse?.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default IntegrationsList;
