import JWT from '@elb/jsonwebtoken';
import { DateTime } from 'luxon';
import moment from 'moment';

import { STATUS_COLOUR_MAP } from 'constants/general';
import {
    ROLE_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
    ROLE_CLIENT_ADMINISTRATOR,
    ROLE_CLIENT_ONBOARDING,
    ROLE_CLIENT_USER,
    ROLE_CLIENT_VIEW_ONLY,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_REGISTRATIONS_RENEWALS,
} from 'constants/role';
import { Response } from 'service';
import NumberFormatting from 'service/NumberFormatting';
import routes from '../routes';

const delay = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
};

const globalDebounce = (fn, name, delay, immediate = true) => {
    if (
        typeof fn !== 'function' ||
        typeof name !== 'string' ||
        typeof delay !== 'number'
    ) {
        console.warn(
            'Please check your globalDebounce implementation! debounce is ignored.'
        );
        return;
    }
    const callNow = immediate && !window[name];
    callNow && fn();
    clearTimeout(window[name]);
    window[name] = setTimeout(() => {
        window[name] = undefined; //reset for immediate
        !callNow && fn();
    }, delay);
};

const canNavigateToRoute = (route, roleRestriction) => {
    if (roleRestriction) {
        return canNavigateWithRestriction(roleRestriction);
    }

    let routeKey = getRouteKey(route);

    return !(
        routeKey.length &&
        typeof routes[routeKey] === 'object' &&
        typeof routes[routeKey].roleRestriction !== 'undefined' &&
        !canNavigateWithRestriction(routes[routeKey].roleRestriction)
    );
};

const canNavigateWithRestriction = (roleRestriction) => {
    return (
        !roleRestriction ||
        (roleRestriction &&
            roleRestriction.some((role) => JWT.hasRole(role), true))
    );
};

const isAdmin = () =>
    canNavigateWithRestriction([
        ROLE_ADMINISTRATOR,
        ROLE_PROSURE_CLIENT_SERVICES,
    ]);
const isAssessorReviewer = () =>
    canNavigateWithRestriction([
        ROLE_ADMINISTRATOR,
        ROLE_PROSURE_CLIENT_SERVICES,
        ROLE_REGISTRATIONS_RENEWALS,
        ROLE_ASSESSMENT_ASSESSOR,
        ROLE_ASSESSMENT_REVIEWER,
    ]);
const isClientAdmin = () =>
    canNavigateWithRestriction([ROLE_CLIENT_ADMINISTRATOR]);

const isClientViewer = () =>
    canNavigateWithRestriction([
        ROLE_CLIENT_ADMINISTRATOR,
        ROLE_CLIENT_USER,
        ROLE_CLIENT_VIEW_ONLY,
        ROLE_CLIENT_ONBOARDING,
    ]);

const isCompanyAdmin = () =>
    canNavigateWithRestriction([ROLE_COMPANY_ADMINISTRATOR]);

const clientInvitationCalculateTotal = (response, spCategory) => {
    let total = 0.0;
    let vat = 0.0;

    if (
        spCategory.requiresProsureAnnualAdministrativeFee === true &&
        response.prosurePricing
    ) {
        total += response.prosurePricing.prosureAdminFeeExcludingTax;
        vat += response.prosurePricing.prosureAdminFeeTax;
    }

    if (spCategory.requiresClientAnnualFee === true && response.clientPricing) {
        total += response.clientPricing.clientChargeExcludingTax;
        vat += response.clientPricing.clientChargeTax;
    }

    return [total, vat];
};

const getRouteKey = (route) => {
    if (!route) {
        return '';
    }

    return route
        .replace(/^\//, '')
        .replace(/\/$/, '')
        .replace(/\//g, '_')
        .replace(/-/g, '_')
        .split('?')[0];
};

const getDefaultAnswerObject = (
    assessmentTypeInstanceResponse,
    questionResponse,
    answerResponse,
    mergingValue = {}
) => {
    if (mergingValue && answerResponse && questionResponse.type === 'trend') {
        mergingValue.value = { ...answerResponse.value, ...mergingValue.value };
    }
    return {
        [questionResponse.id]: {
            _links: {
                question: {
                    href: Response.getLink(questionResponse, 'self'),
                    method: 'GET',
                },
                company: {
                    href: Response.getLink(
                        assessmentTypeInstanceResponse,
                        'company'
                    ),
                    method: 'GET',
                },
                'assessment-type-instance': {
                    href: Response.getLink(
                        assessmentTypeInstanceResponse,
                        'self'
                    ),
                    method: 'GET',
                },
            },
            value: null,
            expiresAt: null,
            ...answerResponse,
            ...mergingValue,
        },
    };
};

const toMoney = (price, allowNull = true) => {
    price = parseFloat(price);
    if (isNaN(price)) {
        return allowNull ? null : '£0.00';
    }

    return NumberFormatting.formatCurrency(price, 'GBP');
};

const parseOptionGroups = (
    items,
    options = [],
    level = 0,
    parent = undefined
) => {
    items.forEach((item) => {
        item.level = level;
        item.parent = parent;
        options.push(item);

        if (item.hasOwnProperty('children') && item.children.length > 0) {
            parseOptionGroups(item.children, options, level + 1, item.id);
        }
    });

    return options;
};

const parseDateString = (stringDate) => DateTime.fromISO(stringDate);
const parseDateFormat = (date, format = 'DD/MM/YYYY, HH:mm:ss') =>
    moment(date).format(format);

// note: breaking sentences into words first before camel-casing
const camelCase = (str) => {
    const arr = str.split(/[/s_]+/);
    return arr.map((str2) => camelCaseWord(str2)).join(' ');
};

const camelCaseWord = (str) => {
    return str.replace(/(?:^\w|[a-zA-Z]|\b\w|\s+)/g, function (match, index) {
        if (/\s+/.test(match)) return '';
        return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
};

// some rfa ratings don't have an image
const hasCreditRatingImageAvailable = (rfaRating) => {
    return (
        [
            'INSOLVENT',
            'DISSOLVED',
            'STRIKE_OFF',
            'PRE_INSOLVENT',
            'NOT_TRADING',
        ].indexOf(rfaRating) === -1
    );
};

const flattenChildren = (data, flatArray = [], childKey = 'children') => {
    flatArray.push(data);

    if (data[childKey]) {
        data[childKey].forEach((child) => flattenChildren(child, flatArray));
    }

    return flatArray;
};

const convertToFlatObject = (obj) => {
    const basicObj = { ...obj };
    Object.keys(basicObj).forEach(async (key) => {
        if (typeof basicObj[key] === 'object' || Array.isArray(basicObj[key])) {
            delete basicObj[key];
        }
    });
    return basicObj;
};

const elipsesString = (string = '', limit = 50) => {
    if (string.length <= limit) {
        return string;
    }

    return `${string.slice(0, limit)}...`;
};

const parseInviteeStatusText = (inviteeStatus) => {
    let updateType = inviteeStatus.updateType;

    if (updateType === 'Linked Service Provider') {
        updateType = `${inviteeStatus.companyName} was linked`;
    } else if (updateType === 'Company Joined Supply Chain') {
        updateType = `${inviteeStatus.companyName} joined the Supply Chain`;
    } else if (updateType === 'Joined Prosure') {
        updateType = `${inviteeStatus.companyName} joined Vantify Supply Chain`;
    } else if (updateType === 'Email Sent') {
        const { emailTemplateName, metaData } = inviteeStatus;
        updateType = `${emailTemplateName} Email Sent to ${metaData.email}`;
    } else if (updateType === 'Contact Info Changed') {
        const metaData = inviteeStatus.metaData;
        let changes = [];

        for (let key in metaData) {
            const property = metaData[key];
            changes.push(`${property.from} became ${property.to}`);
        }

        updateType = `Contact details changed: ${changes.join(', ')}`;
    }

    return (
        <>
            {parseDateFormat(inviteeStatus.updatedOn, 'DD MMM YYYY, HH:mma')} -{' '}
            {updateType} - {''}
            {inviteeStatus.updatedByName}
        </>
    );
};

const getColourClass = (object, key = 'status') => {
    return STATUS_COLOUR_MAP[object[key]] || 'error';
};

export {
    camelCase,
    canNavigateToRoute,
    canNavigateWithRestriction,
    clientInvitationCalculateTotal,
    convertToFlatObject,
    delay,
    elipsesString,
    flattenChildren,
    getColourClass,
    getDefaultAnswerObject,
    getRouteKey,
    globalDebounce,
    hasCreditRatingImageAvailable,
    isAdmin,
    isAssessorReviewer,
    isClientAdmin,
    isClientViewer,
    isCompanyAdmin,
    parseDateFormat,
    parseDateString,
    parseInviteeStatusText,
    parseOptionGroups,
    toMoney
};

