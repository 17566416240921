import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { ButtonLink, Icon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Data, HTTP, Response } from 'service';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
const mapDispatchToProps = {};

class CompanyRegionsEdit extends Component
{
    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    constructor(props)
    {
        super(props);

        this.state = {
            assignedCollection: {},
            availableCollection: {},
            filter: queryString.parse(props.location.search),
            id: props.companyResponse.id,
            isLoading: false,
        };

        this.load = this.load.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        const { companyResponse, history, match } = this.props;

        let data = {
            _links: {
                regions: [],
            },
        };

        this.state.assignedCollection.regions.forEach((region, index) => {
            data._links.regions.push({
                href: Response.getLink(region, 'self'),
            });
        });

        HTTP
            .put(Response.getLink(companyResponse, 'regions'), data)
            .then((response) => {
                if (response) {
                    toast.success('Regions saved');

                    return history.push(match.url.substring(0, match.url.length - 5) + '?order=name');
                }

                toast.error('Unable to save Regions');

                return false;
            })
    }

    getChildren(item)
    {
        let children = item.children || [];

        children.forEach((child) => {
            let lowerChildren = this.getChildren(child);

            lowerChildren.forEach((lowerChild) => {
                children.push(lowerChild);
            });
        });

        return children;
    }

    handleItem(item)
    {
        let { assignedCollection } = this.state;
        let index = findIndex(assignedCollection.regions, {id: item.id});

        let children = this.getChildren(item);

        if (index === -1) {
            assignedCollection.regions.push(item);
            children.forEach((child) => {
                let childIndex = findIndex(assignedCollection.regions, {id: child.id});
                if (childIndex === -1) {
                    assignedCollection.regions.push(child);
                }
            });
        } else if (index !== -1) {
            assignedCollection.regions.splice(index, 1);

            children.forEach((child) => {
                let childIndex = findIndex(assignedCollection.regions, {id: child.id});

                if (childIndex !== -1) {
                    assignedCollection.regions.splice(childIndex, 1);
                }
            });

            if (Response.getLink(item, 'parent')) {
                let parentIndex = findIndex(assignedCollection.regions, {_links: {self: {href: Response.getLink(item, 'parent')}}});

                if (parentIndex !== -1) {
                    assignedCollection.regions.splice(parentIndex, 1);
                }
            }
        }

        this.setState({assignedCollection: {regions: assignedCollection.regions}});
    }

    getEntry(item)
    {
        const { assignedCollection } = this.state;

        let index = findIndex(assignedCollection.regions, {id: item.id});
        let intent = index !== -1 ? 'warning' : 'primary';
        let text = index !== -1 ? 'Remove' : 'Add';

        return (
            <li key={ item.id }>
                { item.treePath }
                <Button type="button"
                    className="float-right"
                    intent={ intent }
                    onClick={ () => { this.handleItem(item); } }>
                    { text }
                </Button>
            </li>
        );
    }

    render()
    {
        const { availableCollection, isLoading, id } = this.state;
        const { regions } = availableCollection;

        const hierarchicalRegions = Data.convertFromFlatStructureToHierarchical(regions);
        const flattenedHierarchy = Data.flattenHierarchy(hierarchicalRegions);
        const itemEntries = flattenedHierarchy.map((item) => {
            return this.getEntry(item);
        });

        return (
            <>
                <div className="CompanyRegionsEdit">
                    <H1>
                        Edit Areas Covered
                    </H1>

                    <Loading isLoading={ isLoading }>
                        <form className="AddEdit"
                            onSubmit={ event => this.handleSubmit(event) }>

                            <ul className="hierarchical-display">
                                { itemEntries }
                            </ul>

                            <FormGroup>
                                <ButtonLink type="button"
                                    intent="default"
                                    className="float-left"
                                    to={ this.props.location.pathname.includes('search') ? `/search/${ id }/regions` : '/company/regions' }>
                                    <Icon icon="ban" />
                                    Cancel
                                </ButtonLink>
                                <Button type="submit"
                                    intent="primary"
                                    className="float-right">
                                    <Icon icon="paper-plane" />
                                    { 'Save' }
                                </Button>
                            </FormGroup>
                        </form>
                    </Loading>
                </div>
            </>
        );
    }

    load(params = {})
    {
        if (this.state.isLoading) {
            return true;
        }

        let filterParams = Object.assign({}, this.props.filter, this.state.filter, params);
        let queryStringFilterParams = queryString.stringify(filterParams)
        if ('?'+queryStringFilterParams !== this.props.location.search) {
            this.props.history.push({search: queryStringFilterParams});
        }

        this.setState({isLoading: true, filter: filterParams});
        const { companyResponse, rootResponse } = this.props;

        HTTP
            .getAll([
                [Response.getLink(companyResponse, 'regions'), {order: 'name', limit: 999}],
                [Response.getLink(rootResponse, 'regions'), {isActive: true, order: 'name', limit: 999}],
            ])
            .then((responses) => {
                if (responses[0] &&
                    responses[1]) {
                    this.setState({isLoading: false, assignedCollection: responses[0].data, availableCollection: responses[1].data});

                    return true;
                }

                toast.error('Unable to fetch Regions');
                this.setState({isLoading: false});

                return false;
            });

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegionsEdit);
