import './background.scss';

interface BackgroundProps {
    preAuth?: boolean
}

const Background = (props: BackgroundProps) => {
    const { preAuth = false } = props;
    const className = `app-background ${preAuth ? 'pre-auth' : ''}`

    return <div className={className} />;
};

export default Background;
