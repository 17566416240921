import { FormGroup, H2 } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updateRegisterForm } from 'action/auth';
import { Icon, SuggestRenderer, TooltipIcon } from 'components/elements';
import { findCountryByLabel } from 'constants/countries';
import { CompanyService, HTTP } from 'service';

let timeout = null;

const CompanySearch = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [companies, setCompanies] = useState([]);
    const store = useStore();

    useEffect(() => {
        // debounce search to not DOS the server
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            searchCompany(searchValue);
        }, 250);
    }, [searchValue]);

    const searchCompany = async (value) => {
        if (!value) {
            setCompanies([]);
            return;
        }

        let queryResult = await HTTP.get(
            `/registration/search?search=${value}&limit=15`
        ).then((response) => {
            return response ? response.data : response;
        });

        if (!queryResult) {
            return toast.error(
                'Unable to search for company. Please fill in manually.'
            );
        }

        const data = queryResult.items.map((queryRow) => {
            const country =
                findCountryByLabel(queryRow.address.country)?.value || 'GB';

            return {
                id: queryRow.company_number,
                name: queryRow.title,
                description: queryRow.description,
                links: queryRow.links,
                registrationNumber: queryRow.company_number,
                creationDate: queryRow.date_of_creation,
                organisationType: queryRow.company_type,
                status: queryRow.company_status,
                addressToString: queryRow.address_snippet,
                address: {
                    addressLine1: [
                        queryRow.address?.premises,
                        queryRow.address?.address_line_1,
                    ].join(', '),
                    addressLine2: queryRow.address?.address_line_2,
                    addressLine3: queryRow.address?.address_line_3,
                    town: queryRow.address?.locality,
                    country,
                    county: queryRow.address?.region,
                    postcode: queryRow.address?.postal_code,
                },
            };
        });
        setCompanies(data);
    };

    const onKeyUp = (event) => {
        if (searchValue !== event.target.value.trim()) {
            setSearchValue(event.target.value);
        }
    };

    const OnItemSelect = (item) => {
        if (!item) {
            setSearchValue('');
            return;
        }

        let formData = Object.assign(store.getState().auth.form, {
            registeredCompanyName: item.name,
            companiesHouseRegistrationNumber: item.registrationNumber,
            addressLine1: item.address.addressLine1,
            addressLine2: item.address.addressLine2,
            addressLine3: item.address.addressLine3,
            town: item.address.town,
            county: item.address.county,
            country: item.address.country,
            postCode: item.address.postcode,
            organisationType: CompanyService.getProsureOrganisationType(
                item.organisationType
            ),
        });

        store.dispatch(updateRegisterForm(formData));
        props.nextPage();
    };

    return (
        <>
            <div className="header">
                <img
                    className="header-logo"
                    src="/assets/images/logo.png"
                    alt="Vantify Supply Chain logo"
                />
            </div>

            <div className="body company-search-body">
                <div className="body-desc">
                    <H2>Create your account</H2>
                </div>
                <FormGroup className="form-fill companySearch">
                    <SuggestRenderer
                        onItemSelect={OnItemSelect}
                        showClearButton={false}
                        items={companies}
                        valueProperty="registrationNumber,name"
                        defaultItem={{}}
                        onKeyUp={onKeyUp}
                        leftElement={<Icon icon="search" />}
                        placeholder="Search for your company at Companies House to start the process..."
                    />
                    <TooltipIcon
                        text="Enter your company name or number, as registered on Companies House. (Results are limited to 15 results)"
                        icon="info-circle"
                    />
                </FormGroup>

                <div>
                    <p className="py-3 text-center">
                        If you don't know your company number, or dont have it,
                        you don't have to worry,
                        <button
                            className="clear-button underline primary"
                            onClick={props.nextPage}
                        >
                            enter your details manually here
                        </button>
                    </p>
                </div>
            </div>

            <div className="footer box-footer flex-start">
                <Link to={props.cancelUrl ?? '/login'}>Cancel</Link>
            </div>
        </>
    );
};

export default CompanySearch;
