import ButtonLink from 'components/elements/ButtonLink';
import 'components/pre-auth/exhibition.scss';

const ExhibitionSignUp = () => {
    return (
        <section className="exhibition-signup-page">
            <section className="signup-card">
                <img
                    src="/assets/images/logo.png"
                    alt="Vantify Supply Chain logo"
                />
                <ButtonLink
                    type="submit"
                    to="register-exhibitions"
                    large={true}
                    intent="primary"
                >
                    Sign up for free
                </ButtonLink>
            </section>
        </section>
    );
};

export default ExhibitionSignUp;
