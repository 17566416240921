import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setChanges } from 'action';
import AssessmentDTSQuestions from 'components/assessments/tabs/questions/AssessmentDTSQuestions';
import TopicQuestions from 'components/assessments/tabs/questions/components/TopicQuestions';
import { VerticalTab } from 'components/elements';
import {
    DTS_QUESTION_CERTIFICATION_NUMBER,
    DTS_QUESTION_SERVICES_UNDERTAKEN,
    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
    DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
} from 'constants/dtsQuestionKeys';
import { SUPPORT_EMAIL } from 'constants/general';
import { getDefaultAnswerObject } from 'helpers/helpers';
import {
    AnswerData,
    AssessmentStateData,
    QuestionData,
    SortedTopic,
    TopicData,
} from 'interface';
import {
    checkIsSSIPDTS,
    clearChildrenError,
    getAnswerOrNew,
    getAssessmentAnswer,
    isValidConditionalQuestion,
    updateError,
    updateSortedTopic,
} from 'service';

const topics = {
    DTS: {
        id: 'dts',
        name: 'Deem-to-Satisfy',
        description: `This assessment type has been set as SSIP Deem to Satisfy. To proceed, you must have a valid assessment with another SSIP scheme operator (Two-Way Deem to Satisfy) or hold a current ISO 45001:2018 certificate, which have incorporated SSIP Health & Safety Core Criteria Questions.
            Where applying through two-way Deem to Satisfy, please provide the name of the SSIP scheme operator. If applying via one-way Deem to Satisfy, please select 'ISO 45001:2018. Please note, only one scheme operator / certification can be added per DTS application.
            If your scheme operator is not listed, please contact us on ${SUPPORT_EMAIL}.`,
    } as TopicData,
    setDates: {
        id: 'setDates',
        name: 'Set Dates',
        description: `This assessment type has been set as requiring a flexible start and end date.`,
    } as TopicData
};

const VerticalQuestions = (props: any) => {
    const { mode, isSubmitDisabled } = props;
    const {
        assessmentTypeInstance,
        sortedQuestions,
        changes,
        errors,
    }: AssessmentStateData = useSelector((state: any) => state.assessments);
    const dispatch = useDispatch();

    const [DTSSortedTopic, setDTSSortedTopic] = useState({
        topic: topics.DTS,
        sortedQuestions: [],
        isOpen: false,
    } as SortedTopic);

    const [CaseByCaseSortedTopic, setCaseByCaseSortedTopic] = useState({
        topic: topics.setDates,
        sortedQuestions: [],
        isOpen: false,
    } as SortedTopic);

    const updateAnswer = (
        answerResponse: AnswerData,
        questionResponse: QuestionData,
        mergingValue: Partial<AnswerData>
    ) => {
        const result = Object.assign(
            {},
            changes,
            getDefaultAnswerObject(
                assessmentTypeInstance,
                questionResponse,
                answerResponse,
                mergingValue
            )
        );
        dispatch(setChanges(result));
        updateError(questionResponse, { ...answerResponse, ...mergingValue });
        clearChildrenError(questionResponse);
    };

    const updateAssessmentAnswer = (key: string, mergingValue: any) => {
        // NOTE: code updated to NOT use merge since scopeCategories would not un-select once selected
        let result = Object.assign({}, changes, { [key]: mergingValue });
        dispatch(setChanges(result));
    };

    // track topic answered questions
    useMemo(() => {
        // for each topic, check which questions have been answered
        sortedQuestions.forEach((topic: SortedTopic) => {
            const showingQuestions = topic.sortedQuestions
                .filter((questionInstance) => isValidConditionalQuestion(questionInstance, topic));

            const answersNotEmpty = showingQuestions
                .map((question) => getAnswerOrNew(question.question))
                .filter(
                    (answer: AnswerData) =>
                        answer.value !== '' ||
                        (answer.files && answer.files.length > 0)
                );

            topic.subtitle = `${answersNotEmpty.length} out of ${showingQuestions.length} answered`;
        });
        //manually check dts
        if (checkIsSSIPDTS()) {
            const answersNotEmpty = [
                getAssessmentAnswer(DTS_QUESTION_CERTIFICATION_NUMBER),
                getAssessmentAnswer(DTS_QUESTION_SERVICES_UNDERTAKEN),
                getAssessmentAnswer(DTS_QUESTION_SSIP_SCHEME_OPERATOR),
                getAssessmentAnswer(
                    DTS_QUESTION_SSIP_SCOPE_CATEGORIES
                ) as string[],
            ].filter((answer: string | string[]) => {
                let answerMerged = answer;
                if (Array.isArray(answer)) {
                    answerMerged = answer.join('');
                }
                return answerMerged && answerMerged.length > 0;
            });

            DTSSortedTopic.subtitle = `${answersNotEmpty.length} out of 4 answered`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changes]);

    // track topic errors
    useMemo(() => {
        sortedQuestions.forEach((topic) => {
            topic.errorMessage = undefined;
            const hasError = !!errors.find(
                (error) => topic.topic.id === error.topic.id
            );
            hasError &&
                (topic.errorMessage =
                    'There are unanswered questions in this topic.');
        });

        DTSSortedTopic.errorMessage = undefined;

        const hasDTSError = !!errors.find(
            (error) => DTSSortedTopic.topic.id === error.topic.id
        );
        hasDTSError &&
            (DTSSortedTopic.errorMessage =
                'There are unanswered questions in this topic.');

        setDTSSortedTopic(DTSSortedTopic);

        CaseByCaseSortedTopic.errorMessage = undefined;

        const hasCaseErrors = errors.find(
            (error) => CaseByCaseSortedTopic.topic.id === error.topic.id
        );
        hasCaseErrors &&
            (CaseByCaseSortedTopic.errorMessage =
                'There are unanswered questions in this topic.');

        setCaseByCaseSortedTopic(CaseByCaseSortedTopic);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const getSPTopicResponseMessage: (topic: SortedTopic) => void = (topic) => {
        const reviewsPassed = topic.sortedQuestions.filter((question) => {
            const answer = getAnswerOrNew(question.question);
            const hasFailedReview = answer?.review?.isApproved === false;

            if (hasFailedReview) {
                topic.warningMessage =
                    'There is assessor feedback requiring your attention.';
            }

            return answer?.review?.isApproved === true;
        });

        if (reviewsPassed.length === topic.sortedQuestions.length) {
            topic.warningMessage = undefined;
            topic.successMessage = 'This topic have been marked as passed.';
        }
    };

    const getReviewerTopicResponseMessage: (topic: SortedTopic) => void = (
        topic
    ) => {
        if (
            [
                'status_submitted_awaiting_assessor',
                'status_pending_awaiting_reviewer',
            ].indexOf(assessmentTypeInstance.status) === -1
        ) {
            return;
        }

        topic.sortedQuestions.forEach((question) => {
            const answer = getAnswerOrNew(question.question);

            if (answer?.review?.isApproved === false) {
                topic.warningMessage = 'Returned Answers';
            }
        });
    };

    const getDTSTab = () => {
        if (!checkIsSSIPDTS()) {
            return null;
        }

        const {
            topic, subtitle, errorMessage, isOpen,
            warningMessage, successMessage
        } = DTSSortedTopic;

        return (
            <VerticalTab
                key={topic.id}
                title={topic.name}
                subtitle={subtitle}
                errorMessage={errorMessage}
                warningMessage={warningMessage}
                successMessage={successMessage}
                open={isOpen}
                onOpenChange={(change: boolean) => {
                    updateSortedTopic({ isOpen: change }, DTSSortedTopic);
                }}
            >
                <div className="topic-description">{topic.description}</div>
                <div className="topic-answer">
                    <AssessmentDTSQuestions
                        assessmentTypeInstanceResponse={assessmentTypeInstance}
                        onUpdate={updateAssessmentAnswer}
                        getAnswer={getAssessmentAnswer}
                    />
                </div>
            </VerticalTab>
        );
    };

    return (
        <>
            {getDTSTab()}
            {sortedQuestions.map((sortedTopic: SortedTopic) => {
                mode === 'review'
                    ? getReviewerTopicResponseMessage(sortedTopic)
                    : getSPTopicResponseMessage(sortedTopic);
                return (
                    <VerticalTab
                        key={sortedTopic.topic.id}
                        title={sortedTopic.topic.name}
                        subtitle={sortedTopic.subtitle}
                        errorMessage={sortedTopic.errorMessage}
                        warningMessage={sortedTopic.warningMessage}
                        successMessage={sortedTopic.successMessage}
                        open={sortedTopic.isOpen}
                        onOpenChange={(change: boolean) => {
                            updateSortedTopic({ isOpen: change }, sortedTopic);
                        }}
                    >
                        <div className="topic-description">
                            {sortedTopic.topic.description}
                        </div>
                        <div className="topic-answer">
                            <TopicQuestions
                                mode={mode}
                                sortedTopic={sortedTopic}
                                isSubmitDisabled={isSubmitDisabled}
                                onUpdate={updateAnswer}
                            />
                        </div>
                    </VerticalTab>
                );
            })}
        </>
    );
};

export default VerticalQuestions;
