export const MAX_FILE_UPLOAD_SIZE_MB = 10;

export const YES_NO_OPTIONS = [
    { label: "Yes", value: "true"  },
    { label: "No", value: "false" },
];

export const OPTIONAL_YES_NO_OPTIONS = [
    { label: "Not Answered", value: ""  },
    ...YES_NO_OPTIONS
];

export const STATUS_COLOUR_MAP = {
    ACTIVE: 'success',
    PENDING: 'warning',
    EXPIRED: 'error',
};

export const SUPPORT_EMAIL = "helpdesk.supplychain@vantify.com"
