interface FooterProps {
    fixed?: boolean;
}

const VantifyFooter = (props: FooterProps) => {
    const { fixed = true } = props;

    return (
        <div className={`vantify-footer ${fixed ? 'fixed' : ''}`}>
            <div className="vantify-logo-wrap">
                <img
                    className="vantify-logo"
                    src="/assets/images/logo-white.svg"
                    alt="Vantify"
                    title="Vantify"
                />
            </div>
            <div className="links-wrap">
                <div>
                    <a
                        href="https://www.vantify.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    <br />
                    <span>
                        Discover the full Vantify ecosystem&nbsp;
                        <a
                            href="https://vantify.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here.
                        </a>
                    </span>
                </div>
            </div>
            <div className="cert-wrap">&nbsp;</div>
        </div>
    );
};

export default VantifyFooter;
