import { useEffect, useState } from 'react';

import { List, TooltipIcon } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import {
    REFUND_REASON_OPTIONS,
    RefundReason,
    RefundReasonMappings,
} from 'interface/Payment/RefundPayment';
import { AssessmentTypeService, Response } from 'service';

import './index.scss';

interface AssessmentsRefundedListFilters {
    rootAssessment: ListSearchFilter;
    refundReason: ListSearchFilter;
}

const AssessmentTypeInstanceRefundedList = (
    props: AssessmentTypeInstanceListProps
) => {
    const { load, collection, filter, isLoading, defaultFilter } = props;
    const [searchFilters, setSearchFilters] =
        useState<AssessmentsRefundedListFilters>({
            rootAssessment: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessment',
                key: 'rootAssessment',
                options: [],
            },
            refundReason: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Refund Reason',
                key: 'refundReason',
                options: REFUND_REASON_OPTIONS,
            },
        });

    const companyRoute = (row: AssessmentTypeInstanceData) => {
        return `/search/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: '-refundedAt',
        status: 'refunded',
    };

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            type: 'string',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            grow: 2,
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Purchased Date',
            type: 'datetime',
            property: 'purchasedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Refunded Date',
            type: 'datetime',
            property: 'refundedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Reason',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                const reason = row.refundReason;
                const detail = row.refundDetail;

                return (
                    reason && (
                        <>
                            {RefundReasonMappings[reason]}
                            {reason === RefundReason.OTHER &&
                                detail &&
                                getRefundReason(row)}
                        </>
                    )
                );
            },
        },
    ];

    const getRefundReason = (data: AssessmentTypeInstanceData) => {
        return (
            <div className='other-refund-reason'>
                <TooltipIcon
                    text={<section>{data.refundDetail}</section>}
                    icon="info"
                />
            </div>
        );
    };

    useEffect(() => {
        (async () => {
            const searchFiltersCopy = { ...searchFilters };
            searchFiltersCopy.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            setSearchFilters(searchFiltersCopy);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page ServiceProviderDetails">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Refunded Assessments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        filters={searchFilters}
                        isLoading={isLoading}
                        defaultFilter={defaultSpecificFilter}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstanceRefundedList;
