import './assessment-buttons.scss';

interface Props {
    children: any
}

const AssessmentButtons = (props: Props) => {
    return (
        <div className="assessment-buttons-container">
            {props.children}
        </div>
    )
}

export default AssessmentButtons;
