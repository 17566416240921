import { Component, createRef } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetRoot } from 'action';
import { logout } from 'action/auth';
import { toggle } from 'action/sidebar';
import {
    Icon,
    ProsureMenu,
    ProtectedRouteGroup,
    ProtectedRouteLink,
} from 'components/elements';
import { ROLE_COMPANY_ADMINISTRATOR } from 'constants/role';
import { isClientViewer } from 'helpers/helpers';
import { ClientService, Response } from 'service';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};

const mapDispatchToProps = {
    logout: logout,
    toggle: toggle,
    resetRoot: resetRoot,
};

class Header extends Component {
    mobileThreshold = 1230;
    optionsMenuRef = createRef();

    constructor(props) {
        super(props);

        this.state = {
            showDesktopMenu: true,
            showMobileMenu: false,
            logo: null,
        };
    }

    showOrHideDesktopMenu = () => {
        let showDesktopMenu = window.innerWidth > this.mobileThreshold;
        this.toggleDocumentClass('is-mobile', !showDesktopMenu);

        if (showDesktopMenu) {
            this.setState({ showMobileMenu: false });
            this.toggleDocumentClass('show-mobile-menu', false);
        }

        // dispatch showDesktopMenuChange event for any desktop menu dependant hooks
        if (this.state.showDesktopMenu !== showDesktopMenu) {
            const event = new CustomEvent('menu:showDesktopMenuChange', {
                detail: { value: showDesktopMenu },
            });
            window.dispatchEvent(event);
        }
        this.setState({ showDesktopMenu });
    };

    toggleDocumentClass = (className, show) => {
        show
            ? document.body.classList.add(className)
            : document.body.classList.remove(className);
    };

    showOrHideMobileMenu = () => {
        let showMobileMenu = !this.state.showMobileMenu;
        this.toggleDocumentClass('show-mobile-menu', showMobileMenu);
        this.hideOptions(); // close options menu if open

        // dispatch showMobileMenuChange event for any mobile menu dependant hooks
        if (this.state.showMobileMenu !== showMobileMenu) {
            const event = new CustomEvent('menu:showMobileMenuChange', {
                detail: { value: showMobileMenu },
            });
            window.dispatchEvent(event);
        }

        this.setState({ showMobileMenu });
    };

    toggleOptionsOpen = (event) => {
        if (this.state.showMobileMenu) {
            this.showOrHideMobileMenu(); // close mobile menu if open
        }
        this.optionsMenuRef.current.classList.toggle('show');
    };

    hideOptions = () => {
        this.optionsMenuRef.current.classList.remove('show');
    };

    loadClientLogo = async () => {
        const { rootResponse } = this.props;

        if (!isClientViewer()) {
            return;
        }

        const clientId = Response.getLinkAttribute(
            rootResponse,
            'client',
            'id'
        );

        if (clientId) {
            const response = await ClientService.loadClient(clientId);
            const logo = Response.getLinkAttribute(
                response,
                'logo',
                'publicUrl'
            );

            this.setState({
                ...this.state,
                logo,
            });
        }
    };

    componentDidMount() {
        this.showOrHideDesktopMenu();
        window.addEventListener('resize', this.showOrHideDesktopMenu);

        if (isClientViewer()) {
            this.loadClientLogo();
        }

        document.addEventListener(
            'mousedown',
            this.checkClickOutsideOptions,
            false
        );
        document.addEventListener(
            'touchend',
            this.checkClickOutsideOptions,
            false
        );
        document.addEventListener('keyup', this.checkClickOutsideOptions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.showOrHideDesktopMenu);
        document.removeEventListener(
            'mousedown',
            this.checkClickOutsideOptions,
            false
        );
        document.removeEventListener(
            'touchend',
            this.checkClickOutsideOptions,
            false
        );
        document.removeEventListener('keyup', this.checkClickOutsideOptions);
    }

    componentDidUpdate(prevProps) {
        const { rootResponse } = this.props;
        const prevRoot = prevProps.rootResponse;

        const currentClient = Response.getLinkAttribute(
            rootResponse,
            'client',
            'id'
        );
        const prevClient = Response.getLinkAttribute(prevRoot, 'client', 'id');

        if (currentClient !== prevClient) {
            this.loadClientLogo();
        }
    }

    checkClickOutsideOptions = (event) => {
        // on keypress esc
        event.key && event.key === 'Escape' && this.hideOptions();

        // if the click did not originate inside options menu
        !this.optionsMenuRef.current.contains(event.target) &&
            this.hideOptions();
    };

    getClientLogo = () => {
        const { logo } = this.state;

        if (!logo) {
            return null;
        }

        return (
            <div className="Header__navigation_logo client-logo">
                <img
                    src={process.env.REACT_APP_BASE_URL + logo}
                    alt="Client Logo"
                />
            </div>
        );
    };

    handleLogout = () => {
        this.props.logout();
        this.props.resetRoot();
    };

    render() {
        const { showDesktopMenu, showMobileMenu } = this.state;
        return (
            <>
                <header>
                    <div
                        className="header-section"
                        data-is-mobile={isMobile ? 'true' : 'false'}
                    >
                        <Link
                            className="Header__navigation_logo"
                            to={'/dashboard'}
                            replace
                        >
                            <img
                                src="/assets/images/header-logo.svg"
                                alt="Vantify Supply Chain logo"
                            />
                        </Link>
                        {this.getClientLogo()}
                        <div className="Header__navigation_spacer" />
                        <ProsureMenu
                            type="desktop"
                            show={showDesktopMenu}
                            onMenuOpen={() => this.hideOptions()}
                        />
                        {showDesktopMenu ? null : (
                            <button
                                className="prosure-menu-mobile-toggle"
                                onClick={this.showOrHideMobileMenu}
                            >
                                <Icon icon="bars" />
                            </button>
                        )}
                        <nav
                            className="menu --options-menu trigger-click"
                            ref={this.optionsMenuRef}
                        >
                            <ul>
                                <ProtectedRouteLink
                                    icon="cog"
                                    className="options-menu-toggle"
                                    onClick={this.toggleOptionsOpen}
                                >
                                    <ProtectedRouteGroup>
                                        <ProtectedRouteLink
                                            onClick={this.toggleOptionsOpen}
                                            route="/profile"
                                        />
                                        <ProtectedRouteLink
                                            onClick={this.toggleOptionsOpen}
                                            route="/contact-us"
                                            label="Contact Us"
                                        />
                                        <ProtectedRouteLink
                                            onClick={this.toggleOptionsOpen}
                                            route="/integrations"
                                            roleRestriction={[
                                                ROLE_COMPANY_ADMINISTRATOR,
                                            ]}
                                        />
                                        <ProtectedRouteLink
                                            onClick={this.handleLogout}
                                            label="Logout"
                                        />
                                    </ProtectedRouteGroup>
                                </ProtectedRouteLink>
                            </ul>
                        </nav>
                    </div>
                </header>
                <ProsureMenu
                    type="mobile"
                    show={showMobileMenu}
                    onClick={this.showOrHideMobileMenu}
                />
                <div
                    className="prosure-menu-mobile-backdrop"
                    onClick={this.showOrHideMobileMenu}
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
