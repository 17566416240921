import { Accordion, MultiSwitchToggle } from 'components/elements';

interface LinkedFilterProps {
    clear: boolean;
    onUpdate: (...args: any) => any;
    initialValue?: string;
}

const LinkedFilter = (props: LinkedFilterProps) => {
    const isLinkedOptions = [
        {
            id: 'LINKED_CLIENT',
            key: 'isLinked',
            value: props.initialValue === 'LINKED_CLIENT' ?? false,
            label: 'Linked To You',
        },
        {
            id: 'LINKED',
            key: 'isLinked',
            value: props.initialValue === 'LINKED' ?? false,
            label: 'Linked To Others',
        },
        {
            id: 'NOT_LINKED',
            key: 'isLinked',
            value: props.initialValue === 'NOT_LINKED' ?? false,
            label: 'Not Linked',
        },
    ];

    return (
        <Accordion title="Vantify CAFM">
            <MultiSwitchToggle
                options={isLinkedOptions}
                onlyOneActive={true}
                onUpdate={(options: Array<any>) => props.onUpdate(options)}
                clear={props.clear}
            />
        </Accordion>
    );
};

export default LinkedFilter;
