import { get, has } from 'lodash';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import List from 'components/elements/List';
import { SLA_STATUSES } from 'constants/assessmentTypeInstance';
import {
    ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES,
    ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES_CLIENT,
    ASSESSMENT_TYPE_MILESTONES
} from 'constants/assessmentTypeMilestones';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { isClientViewer } from 'helpers/helpers';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, Response, UserService } from 'service';

import { YES_NO_OPTIONS } from 'constants/general';
import './index.scss';

interface AssessmentInReviewListFilters {
    rootAssessment: ListSearchFilter;
    currentStatus: ListSearchFilter;
    lastAssessedBy: ListSearchFilter;
    assessmentType: ListSearchFilter;
}

const AssessmentTypeInstanceInReviewList = (
    props: AssessmentTypeInstanceListProps
) => {
    const filterParams = queryString.parse(props.location.search);

    const { load, onClear, collection, filter, isLoading, defaultFilter } =
        props;
    const isFilterOpenOnLoad =
        has(filterParams, 'currentStatus') ||
        has(filterParams, 'lastAssessedBy') ||
        has(filterParams, 'rootAssessment');

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: '-lastStatusUpdate',
        status: 'review-assess',
        clientOnly: false,
        isClientAssessed: false,
    };

    const isClientUser = isClientViewer();

    if (isClientUser === true) {
        defaultSpecificFilter.clientOnly = true;
    }

    const [searchFilters, setSearchFilters] =
        useState<AssessmentInReviewListFilters>({
            rootAssessment: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessment',
                key: 'rootAssessment',
                options: [],
            },
            lastAssessedBy: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessor',
                key: 'lastAssessedBy',
                options: [],
            },
            currentStatus: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Current Status',
                key: 'currentStatus',
                options:
                    isClientUser === true
                        ? ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES_CLIENT
                        : ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES,
            },
            assessmentType: {
                type: 'single-select',
                defaultValue: { label: "No", value: "false" },
                label: 'Is Client Assessed?',
                key: 'isClientAssessed',
                options: YES_NO_OPTIONS
            },
        });

    const getRoute = (row: AssessmentTypeInstanceData) => {
        return `/review/assessments/${row.id}/view`;
    };

    const companyRoute = (row: AssessmentTypeInstanceData) => {
        return `/search/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    let columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            type: 'string',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Current Status',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                return 'Submitted - ' + ASSESSMENT_TYPE_MILESTONES[row.status];
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Date Of Last Status Update',
            type: 'datetime',
            property: 'lastStatusUpdate',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Fast-Track',
            type: 'callback',
            property: 'isFastTrack',
            callback: (row: AssessmentTypeInstanceData) => {
                if (get(row, 'SLAStatus') === 'sla_not_applicable') {
                    return 'N/A';
                }

                return get(row, 'isFastTrack') === true ? 'Yes' : 'No';
            },
            sortable: false, // @todo P360-556, false always for now
            grow: 1,
        },
        {
            name: 'Assessor SLA',
            type: 'callback',
            property: 'SLADueAt',
            callback: (row: AssessmentTypeInstanceData) => {
                const SLAStatus = get(row, 'SLAStatus');

                if (SLAStatus === 'sla_not_applicable') {
                    return 'N/A';
                }

                const SLADueAt = get(row, 'SLADueAt');
                const ResolvedAt = get(row, 'SLAResolvedAt');

                if (SLADueAt !== undefined && SLAStatus !== undefined) {
                    let formattedDateTime = ResolvedAt ?? SLADueAt;

                    formattedDateTime = DateTime.fromISO(
                        formattedDateTime
                    ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

                    let slaClass =
                        SLAStatus === 'sla_met' || SLAStatus === 'sla_failed'
                            ? SLAStatus
                            : '';

                    return (
                        <span className={slaClass}>
                            {SLA_STATUSES[SLAStatus]} - {formattedDateTime}
                        </span>
                    );
                }
            },
            sortable: true,
            grow: 2,
        },
        {
            name: 'Assessor',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                const value = get(row, 'lastAssessedBy');
                return value ? value : 'tbc';
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Reviewer',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                const assessmentClient = get(row, 'clientName');

                if (typeof assessmentClient !== 'undefined') {
                    return 'N/A';
                }
                const value = get(row, 'lastReviewedBy');
                return value ? value : 'tbc';
            },
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    useEffect(() => {
        (async () => {
            const copySearchFilters: AssessmentInReviewListFilters = {
                ...searchFilters,
            };

            copySearchFilters.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            copySearchFilters.lastAssessedBy.options =
                await UserService.getAssessorDropdown();

            setSearchFilters(copySearchFilters);

            let loadParams = {
                ...defaultFilter,
                order: '-lastStatusUpdate',
                status: 'review-assess',
            };

            if (typeof filterParams.currentStatus === 'string') {
                loadParams.currentStatus = filterParams.currentStatus;
            }

            if (typeof filterParams.page === 'string') {
                loadParams.page = Number(filterParams.page);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page ServiceProviderDetails">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Live Assessments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        preIsFilterOpen={isFilterOpenOnLoad}
                        isLoading={isLoading}
                        filters={searchFilters}
                        defaultFilter={defaultSpecificFilter}
                        onClear={onClear}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstanceInReviewList;
