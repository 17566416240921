import { H1 } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';

import {
    ItemCount,
    LoadingIndicator,
    Reload,
    Table,
} from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { HTTP, Response } from 'service';

class CompanyServicesList extends Component {
    columns = [
        { name: 'Name', property: 'treePath', sortable: true, grow: 12 },
    ];

    defaultFilters = {
        limit: 999,
        order: 'treePath',
    };

    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            collection: {},
            filter: queryString.parse(props.location.search),
            id: props.companyResponse.id,
            isLoading: false,
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const { collection, isLoading } = this.state;
        const { companyResponse } = this.props;

        return (
            <div className="CompanyServicesList">
                <Loading isLoading={isLoading}>
                    <H1>
                        Services
                        {isLoading ? (
                            <LoadingIndicator inline={true} size="sm" />
                        ) : (
                            <ItemCount
                                count={
                                    collection.count +
                                    (typeof companyResponse.otherService ===
                                        'string' &&
                                    companyResponse.otherService.length >= 1
                                        ? 1
                                        : 0)
                                }
                            />
                        )}
                        <Reload load={this.load} />
                    </H1>

                    <NoResultsFound
                        count={
                            collection.count +
                            (typeof companyResponse.otherService === 'string' &&
                            companyResponse.otherService.length >= 1
                                ? 1
                                : 0)
                        }
                    >
                        {collection.count ? (
                            <Table
                                data={collection.services}
                                columns={this.columns}
                                onSort={this.load}
                                ordering={this.state.filter.order}
                            />
                        ) : null}

                        {typeof companyResponse.otherService === 'string' &&
                        companyResponse.otherService.length >= 1 ? (
                            <dl className="horizontal padded-left">
                                <dt>Other Service</dt>
                                <dd>{companyResponse.otherService}</dd>
                            </dl>
                        ) : null}
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (
            this.state.isLoading ||
            typeof this.props.companyResponse.id === 'undefined'
        ) {
            return;
        }

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );
        let queryStringFilterParams = queryString.stringify(filterParams);
        if ('?' + queryStringFilterParams !== this.props.location.search) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        const { companyResponse } = this.props;

        HTTP.get(
            Response.getLink(companyResponse, 'services'),
            filterParams
        ).then((response) => {
            if (response) {
                this.setState({ isLoading: false, collection: response.data });

                return true;
            }

            toast.error('Unable to fetch Services');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default CompanyServicesList;
