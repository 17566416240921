import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { FormEvent, useEffect, useState } from 'react';

import {
    ButtonLink,
    Icon,
    InputElementIcon,
    SuggestRenderer,
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import {
    ELBClientWithIntegrationListData,
    ElogbooksClientsFilter,
    IntegrationData,
    IntegrationsComponentProps,
    ELBClientIntegrationData,
} from 'interface/Integrations';

import { globalDebounce } from 'helpers/helpers';
import {
    createElogbooksClientFilter,
    createIntegrationObject,
    loadELBClientsWithIntegrations,
    redirectToIntegration,
} from 'service/Integration/IntegrationService';

import { Routing } from 'service';

const IntegrationAdd = (props: Partial<IntegrationsComponentProps>) => {
    const { history, integrationType, match } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [ELBClientFilter, setELBClientFilter] = useState<
        Partial<ElogbooksClientsFilter>
    >(createElogbooksClientFilter());

    const [ELBClients, setELBClients] = useState<ELBClientIntegrationData[]>(
        []
    );
    const [form, setForm] = useState<IntegrationData>(
        createIntegrationObject(integrationType)
    );

    const loadClients = (callback?: Function) => {
        globalDebounce(
            async () => {
                const ELBClientList: ELBClientWithIntegrationListData =
                    await loadELBClientsWithIntegrations(ELBClientFilter);
                setELBClients(ELBClientList.clients);
                setIsLoading(false);
                callback && callback();
            },
            'ELBClients',
            80
        );
    };

    const searchClients = (client?: string) => {
        const filter = { ...ELBClientFilter, client: client };
        !client && delete filter.client;
        setELBClientFilter(filter);
    };

    const selectClient = (item: any) => {
        if (item === null) {
            searchClients('');

            return;
        }

        setForm({
            ...form,
            integrationURL: item.elogsUrl, //e.target.value,
        });

        return;
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            loadClients();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(loadClients, [ELBClientFilter]);

    return (
        <div>
            <H1>Add Integration</H1>

            <Loading isLoading={isLoading}>
                <form
                    className="AddEdit"
                    onSubmit={(e: FormEvent<HTMLFormElement>) =>
                        redirectToIntegration(e, form)
                    }
                >
                    {ELBClients.length > 0 && (
                        <FormGroup
                            label="Vantify CAFM Clients"
                            key="elbClient"
                            inline={true}
                            className="form-fill required"
                            labelFor="elbClient"
                        >
                            <SuggestRenderer
                                id="elbClient"
                                onItemSelect={(item: any) => selectClient(item)}
                                placeholder={'Select Vantify CAFM Client'}
                                valueProperty={'name'}
                                items={ELBClients}
                                onKeyUp={(e: any) => {
                                    searchClients(e.target.value);
                                }}
                                isRequired={true}
                            />
                        </FormGroup>
                    )}

                    {ELBClients.length === 0 && (
                        <p>
                            No Clients currently available with Elogs link
                            Active
                        </p>
                    )}

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(
                                history,
                                match,
                                null
                            )}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        {ELBClients.length > 0 && (
                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right"
                            >
                                <Icon icon="paper-plane" />
                                Create
                            </Button>
                        )}
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default IntegrationAdd;
