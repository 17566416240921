import { useEffect, useState } from 'react';

import List from 'components/elements/List';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, InstanceService, Response } from 'service';

import { toast } from 'react-toastify';
import './index.scss';

interface AssessmentExpiringListFilters {
    rootAssessment: ListSearchFilter;
}

const AssessmentTypeInstanceExpiringList = (
    props: AssessmentTypeInstanceListProps
) => {
    const { load, collection, filter, isLoading, defaultFilter } = props;
    const [searchFilters, setSearchFilters] =
        useState<AssessmentExpiringListFilters>({
            rootAssessment: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessment',
                key: 'rootAssessment',
                options: [],
            },
        });

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: 'expiryDate',
        status: 'expiring',
    };

    const companyRoute = (row: AssessmentTypeInstanceData) => {
        return `/search/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            grow: 2,
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Completed Date',
            type: 'datetime',
            property: 'completedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Expiry Date',
            type: 'datetime',
            property: 'expiryDate',
            sortable: true,
            grow: 2,
        },
    ];

    useEffect(() => {
        (async () => {
            const searchFiltersCopy = { ...searchFilters };
            searchFiltersCopy.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            setSearchFilters(searchFiltersCopy);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownload = async () => {
        await InstanceService.downloadList(filter, 'expiring-assessments')
            .then(() => {
                toast.success('Assessment Type List Downloaded');
            })
            .catch(() => {
                toast.error('Assessment Type List Could Not Be Downloaded');
            });
    };

    return (
        <div className="page ServiceProviderDetails">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Expiring Assessments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        filters={searchFilters}
                        isLoading={isLoading}
                        defaultFilter={defaultSpecificFilter}
                        downloadHandler={onDownload}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstanceExpiringList;
