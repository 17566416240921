import { Button, H1, H2, Slider } from '@blueprintjs/core';
import { SyntheticEvent, useEffect, useReducer } from 'react';

import { ButtonLink, Table } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { globalDebounce } from 'helpers/helpers';
import type { FlatObject } from 'interface';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { HTTP, Response, Routing } from 'service';

const IntegationsAddMultiLinksTable = (props: any) => {
    const { match } = props;

    const rootResponse = useSelector((state: any) => state.root.response);
    const userId = rootResponse.user.id;

    const [state, setState]: [
        state: any,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer((state: any, edits: any) => ({ ...state, ...edits }), {
        clientId: match.params.clientId,
        client: null,
        suggestedLinks: [],
        selectedSuggestedLinks: [],
        accuracy: 2,
    });

    const loadClient = async () => {
        const response = await HTTP.action(
            'get',
            `/clients/${state.clientId}`,
            { datagroup: 'details' }
        );

        setState({
            client: response?.data ?? null,
        });
    };

    const loadSuggestedLinks = async () => {
        globalDebounce(
            async () => {
                const response = await HTTP.action(
                    'get',
                    Response.getLink(state.client, 'suggestedLinks') ??
                        `/clients/${state.clientId}/suggested-links`,
                    { datagroup: 'details', accuracy: state.accuracy / 100 },
                    {},
                    'No saved links are available for this client. Please pick another'
                );

                setState({
                    selectedSuggestedLinks: [],
                    suggestedLinks: response?.data ?? [],
                    isLoading: false,
                });
            },
            'suggestedLinks',
            500,
            true
        );
    };

    useEffect(() => {
        if (state.client !== null) {
            setState({ isLoading: true });
            loadSuggestedLinks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.client]);

    useEffect(() => {
        if (state.client !== null) {
            loadSuggestedLinks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.accuracy]);

    useEffect(() => {
        loadClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const linkSuggestedLink = async (selectedLink: FlatObject) => {
        const response = await HTTP.action(
            'post',
            '/integrations',
            {
                userId,
                companyId: selectedLink.company.id,
                clientName: selectedLink.elogbooksSp.elogbooksClient,
                serviceProviderAdd: [
                    {
                        id: selectedLink.elogbooksSp.elogbooksID,
                        name: selectedLink.elogbooksSp.elogbooksName,
                    },
                ],
                serviceProviderRemove: [],
            },
            {},
            'Unable to create integration links'
        );

        if (response) {
            loadSuggestedLinks();
            toast.success('Link created successfully');
        }
    };

    const linkSuggestedLinks = async () => {
        const first = state.selectedSuggestedLinks[0];
        const response = await HTTP.action(
            'post',
            '/integrations',
            {
                userId,
                companyId: first.company.id,
                clientName: state.client.elogsClientName,
                serviceProviderAdd: state.selectedSuggestedLinks.map(
                    (item: FlatObject) => {
                        return {
                            companyId: item.company.id,
                            id: item.elogbooksSp.elogbooksID,
                            name: item.elogbooksSp.elogbooksName,
                        };
                    }
                ),
                serviceProviderRemove: [],
            },
            {},
            'Unable to create integration links'
        );

        if (response) {
            loadSuggestedLinks();
            toast.success('Links created successfully');
        }
    };

    let columns = [
        {
            property: 'id',
            type: 'checkbox',
            grow: 1,
            onChange: (selection: Object) => {
                setState({ selectedSuggestedLinks: Object.values(selection) });
            },
        },
        {
            name: 'Vantify Service Provider',
            property: 'prosureServiceProviderName',
            type: 'callback',
            sortable: false,
            grow: 3,
            callback: (selectedLink: FlatObject) => {
                let name = selectedLink.company.registeredCompanyName;
                if (selectedLink.company.tradingName) {
                    name += ` (${selectedLink.company.tradingName})`;
                }

                return name;
            },
        },
        {
            name: 'Vantify CAFM Service Provider',
            property: 'elogsServiceProviderName',
            type: 'callback',
            sortable: false,
            grow: 3,
            callback: (selectedLink: FlatObject) => {
                return selectedLink.elogbooksSp.elogbooksName;
            },
        },
        {
            name: '',
            property: '',
            type: 'button',
            grow: 1,
            onClick: (event: SyntheticEvent, row: FlatObject) => {
                linkSuggestedLink(row);
            },
            intent: 'primary',
            text: 'Link',
            right: true,
            className: 'icon-free',
        },
    ];

    return (
        <div className="integrations-suggested-links">
            <H1>Suggested Links for {state.client?.name}</H1>

            <div className="bp3-card mb-3">
                <H2>Accuracy</H2>
                <p className="mb-4">
                    Adjust the accuracy of suggested links, low accuracy
                    increases the matches found for a Service Provider, by
                    including partial matches, etc
                </p>
                <Slider
                    min={0}
                    max={100}
                    stepSize={1}
                    labelStepSize={10}
                    labelRenderer={(accuracy: number) => accuracy + '%'}
                    onChange={(accuracy: number) => setState({ accuracy })}
                    value={state.accuracy}
                    vertical={false}
                />
            </div>

            <Loading isLoading={state.isLoading}>
                <Table data={state.suggestedLinks} columns={columns} />

                <div className="pt-4 pb=2 px-4">
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={Routing.getRouteUpOneLevel(
                            props.history,
                            match,
                            null
                        )}
                    >
                        Cancel
                    </ButtonLink>
                    {state.selectedSuggestedLinks.length !== 0 ? (
                        <Button
                            intent="primary"
                            className="float-right "
                            onClick={linkSuggestedLinks}
                        >
                            Link all selected
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
            </Loading>
        </div>
    );
};

export default IntegationsAddMultiLinksTable;
