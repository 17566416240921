const routes = {
    admin_integrations: {
        href: '/admin/integrations',
        text: 'Integrations',
        skipNavigation: true,
    },
    admin_integrations_elogbooks: {
        href: '/admin/integrations/elogbooks',
        text: 'Vantify CAFM',
        skipNavigation: false,
    },
    admin_integrations_elogbooks_add: {
        href: '/admin/integrations/elogbooks/add',
        text: 'Add',
        skipNavigation: true,
    },
};

export default routes;
