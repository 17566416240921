import { H1 } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';

import {
    ItemCount,
    LoadingIndicator,
    Reload,
    Table,
} from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { HTTP, Response } from 'service';

class CompanyRegionsList extends Component {
    columns = [
        { name: 'Name', property: 'treePath', sortable: true, grow: 12 },
    ];

    defaultFilters = {
        limit: 999,
        order: 'treePath',
    };

    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            collection: {},
            filter: queryString.parse(props.location.search),
            id: props.companyResponse.id,
            isLoading: false,
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const { collection, isLoading } = this.state;

        return (
            <div className="CompanyRegionsList">
                <Loading isLoading={isLoading}>
                    <H1>
                        Regions
                        {isLoading ? (
                            <LoadingIndicator inline={true} size="sm" />
                        ) : (
                            <ItemCount count={collection.count} />
                        )}
                        <Reload load={this.load} />
                    </H1>

                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.regions}
                            columns={this.columns}
                            onSort={this.load}
                            ordering={this.state.filter.order}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );
        let queryStringFilterParams = queryString.stringify(filterParams);
        if ('?' + queryStringFilterParams !== this.props.location.search) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        const { companyResponse } = this.props;

        HTTP.get(
            Response.getLink(companyResponse, 'regions'),
            filterParams
        ).then((response) => {
            if (response) {
                this.setState({ isLoading: false, collection: response.data });

                return true;
            }

            toast.error('Unable to fetch Regions');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default CompanyRegionsList;
