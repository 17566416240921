import { Button, H1 } from '@blueprintjs/core';
import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import { ButtonLink, Icon } from 'components/elements';
import { EmailTemplateProps } from 'interface/Email';
import { sendSampleEmail } from 'service/Email/TemplateService';

const TemplateInfo = (props: EmailTemplateProps) => {
    const { template, match } = props;
    const descriptionRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (!descriptionRef) return;
        if (!descriptionRef.current) return;
        let content = template.sample
            ?.replaceAll('\n', '')
            .replaceAll('cid:@assets/logo.png', '/assets/images/logo.svg');
        let iframe = descriptionRef.current as HTMLIFrameElement;
        iframe.contentWindow?.document.open();
        iframe.contentWindow?.document.write(content);
        iframe.contentWindow?.document.close();
    }, [descriptionRef, template]);

    return (
        <div className="ServiceDetails">
            <H1>
                Info
                <div className="float-right">
                    <ButtonLink
                        type="button"
                        to={`${match.url}/edit`}
                        intent="primary"
                        className="me-3"
                    >
                        <Icon icon="lock-open" />
                        Edit
                    </ButtonLink>
                    <Button onClick={() => sendSampleEmail(template)}>
                        Get Sample Email
                    </Button>
                </div>
            </H1>
            <dl className="horizontal">
                <dt>Slug</dt>
                <dd>{template.slug}</dd>
                <dt>Name</dt>
                <dd>{template.name}</dd>
                <dt>Subject</dt>
                <dd>{template.subject}</dd>
                <dt>Description</dt>
                <dd>{template.description}</dd>
                <dt>Content</dt>
                <dd>
                    <div ref={descriptionRef} />
                    <iframe
                        title="iframe"
                        ref={descriptionRef}
                        width="1000"
                        height="900px"
                    />
                </dd>
            </dl>
        </div>
    );
};

export default withRouter(TemplateInfo);
