import { Button, FormGroup } from '@blueprintjs/core';
import { Icon, VantifyFooter } from 'components/elements';
import { SUPPORT_EMAIL } from 'constants/general';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HTTP } from 'service';

class ForgottenPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const { history } = this.props;

        return HTTP.post('/forgotten-password', {
            email: this.state.email,
        }).then((response) => {
            if (response) {
                toast.success('Password reset request successful');

                return history.push('/');
            }

            toast.error('Unable to request a password reset');

            return false;
        });
    }

    render() {
        return (
            <div className="forgotton-password-page vantify-theme box-page">
                <div className="forgotton-password-box box">
                    <div className="box-body">
                        <img
                            className="box-logo"
                            src="/assets/images/logo.png"
                            alt="Vantify Supply Chain logo"
                        />
                        <div className="box-body-inner">
                            {this.forgottonPasswordForm()}
                        </div>
                    </div>
                    <div className="box-footer">
                        <a href="tel:0203 404 6750">
                            <Icon icon="phone-square" />
                            <span>0203 404 6750</span>
                        </a>
                        <a href={`mailto:${SUPPORT_EMAIL}`}>
                            <Icon icon="envelope" />
                            <span>{SUPPORT_EMAIL}</span>
                        </a>
                    </div>
                </div>
                <VantifyFooter />
            </div>
        );
    }

    forgottonPasswordForm = () => {
        return (
            <form
                className="FlexContainerFields"
                onSubmit={(e) => this.handleSubmit(e)}
            >
                <FormGroup>
                    <input
                        id="email"
                        type="email"
                        className="bp3-fill form-field required"
                        placeholder="Email Address"
                        autoComplete="username"
                        value={this.state.email}
                        onChange={(event) =>
                            this.setState({ email: event.target.value })
                        }
                        required
                    />
                    <div className="input-error">
                        Please input a valid email address
                    </div>
                </FormGroup>
                <FormGroup className="form-buttons">
                    <Link className="float-left" to="/login">
                        Cancel
                    </Link>

                    <Button
                        type="submit"
                        intent="primary"
                        large={true}
                        className="float-right"
                    >
                        Request Password Reset
                    </Button>
                </FormGroup>
            </form>
        );
    };
}

export default ForgottenPassword;
