import { Redirect, Route, Switch } from "react-router-dom";

import SupplyChainSearch from "components/supply-chain/SupplyChainSearch";
import SupplyChainAnalytics from "components/supply-chain/analytics/SupplyChainAnalytics";
import SupplyChainElogsCAFM from "components/supply-chain/vantify-cafm-stats/ElogsCAFM";

const SupplyChain = (props) => {
    return <>
        <Switch>
            <Route path="/supply-chain/list" component={ SupplyChainSearch } />
            <Route path="/supply-chain/pending" component={ SupplyChainSearch } />
            <Route path="/supply-chain/analytics" component={ SupplyChainAnalytics } />
            <Route path="/supply-chain/vantify-cafm-stats" component={ SupplyChainElogsCAFM } />
            <Redirect to="/supply-chain/list" />
        </Switch>
    </>
};

export default SupplyChain;
