const routes = {
    search: {
        href: '/search',
        text: 'Search',
    },
    search_view: {
        href: '/search/:companyId',
        text: '{serviceProvider.registeredCompanyName}',
    },
    search_users: {
        href: '/search/:companyId/users',
        text: 'Users',
    },
    search_users_add: {
        href: '/search/:companyId/users/add',
        text: 'Add a User',
        skipNavigation: true,
    },
    search_services: {
        href: '/search/:companyId/services',
        text: 'Services',
    },
    search_clients: {
        href: '/search/:companyId/clients',
        text: 'Clients',
    },
    search_services_edit: {
        href: '/search/:companyId/services/edit',
        text: 'Edit Services',
        skipNavigation: true,
    },
    search_areas: {
        href: '/search/:companyId/regions',
        text: 'Areas Covered',
    },
    search_areas_edit: {
        href: '/search/:companyId/regions/edit',
        text: 'Edit Areas',
        skipNavigation: true,
    },
    search_assessments: {
        href: '/search/:companyId/assessments',
        text: 'Assessments',
    },
    search_assessments_view: {
        href: '/search/:companyId/assessments/:instanceId/view',
        text: '{assessmentType.name}',
        skipNavigation: true,
    },
    search_insurances: {
        href: '/search/:companyId/insurances?currentOrExpired=current&order=-expiry',
        text: 'Insurances',
        icon: 'house-damage',
    },
    search_insurance_add: {
        href: '/search/:companyId/insurances/add',
        text: 'Add Insurance',
        skipNavigation: true,
    },
    search_insurance_details: {
        href: '/search/:companyId/insurances/:id',
        text: 'View Insurance',
        icon: 'house-damage',
    },
    search_insurance_details_edit: {
        href: '/search/:companyId/insurances/:id/edit',
        text: 'Edit',
        icon: 'house-damage',
    },
    search_certificate_memberships: {
        href: '/search/:companyId/certification-memberships?currentOrExpired=current&order=-expiry',
        text: 'Certification & Memberships',
        icon: 'file-certificate',
    },
    search_certificate_membership_add: {
        href: '/search/:companyId/certification-memberships/add',
        text: 'Add Certification/Membership',
        skipNavigation: true,
    },
    search_certificate_membership_details: {
        href: '/search/:companyId/certification-memberships/:id',
        text: 'View Certification/Membership',
        icon: 'file-certificate',
    },
    search_certificate_membership_edit: {
        href: '/search/:companyId/certification-memberships/:id/edit',
        text: 'Edit',
        icon: 'file-certificate',
    },
    search_contracts: {
        href: '/search/:companyId/contracts',
        text: 'Contracts',
        icon: 'file-certificate',
    },
    search_contracts_add: {
        href: '/search/:companyId/contracts/add',
        text: 'Add Contract',
        skipNavigation: true,
    },
    search_contracts_view: {
        href: '/search/:companyId/contracts/:id',
        text: 'Contract Details',
        icon: 'file-certificate',
    },
    search_contracts_edit: {
        href: '/search/:companyId/contracts/:id/edit',
        text: 'Edit',
        icon: 'file-certificate',
    },
};

export default routes;
